<!-- Body Start-->
<div class="main-wrapper">
  <div class="banner-wrapper first last">
    <div class="row no-gutters m-0">
      <div class="col-md-6 col-12">
        <div class="container">
          <div class="page-title-wrap">
            <h1 class="page-title">{{ 'LANDING.BANNER.TITLE' | translate }}</h1>
            <div class="subtitle">{{ 'LANDING.BANNER.SUBTITLE' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="banner-img">
          <img
            src="../../../assets/images/banner-1.png"
            width="1000"
            height="585"
            alt="Eligibility Criteria"
            loading="lazy"
            class="image-style-wide"
          />
        </div>
      </div>
    </div>
  </div>

  <section class="py-5 light-blue-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="heading mb-5 mt-4 text-center">{{ 'DASHBOARD.APPLICATIONPROCESS.TITLE' | translate }}</h2>

          <ul class="steps mb-1">
            <!-- <li class="active">
              <p>{{ 'DASHBOARD.APPLICATIONPROCESS.STEP1' | translate }}</p>
            </li> -->

            <!-- <li class="active">
              <p>{{ 'DASHBOARD.APPLICATIONPROCESS.STEP2' | translate }}</p>
            </li> -->

            <!-- <li class="active">
              <p>{{ 'HEADER.MENU2' | translate }}</p>
            </li> -->
            <!-- 
            <li class="active">
              <p>{{ 'DASHBOARD.APPLICATIONPROCESS.STEP4' | translate }}</p>
            </li> -->
            <!-- 
            <li class="active">
              <p>{{ 'DASHBOARD.APPLICATIONPROCESS.STEP5' | translate }}</p>
            </li> -->

            <!-- <li class="active">
              <p>{{ 'DASHBOARD.APPLICATIONPROCESS.STEP6' | translate }}</p>
            </li> -->

            <!-- <li class="active">
              <p>{{ 'HEADER.MENU3' | translate }}</p>
            </li> -->

            <li class="active">
              <p [routerLink]="['/eligibility-criteria']">
                Step 1<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP1' | translate }}
              </p>
            </li>

            <li class="active">
              <p (click)="getAKPK()">Step 2<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP3' | translate }}</p>
            </li>

            <!-- <li class="active">
              <p>Step 2<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP3' | translate }}</p>
            </li> -->

            <li class="active">
              <p style="white-space: pre-line">Step 3<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP4' | translate }}</p>
            </li>
            <li class="active">
              <p style="white-space: pre-line">Step 4<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP5' | translate }}</p>
            </li>

            <!-- <li class="active">
              <p [routerLink]="['/application']">Step 5<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP6' | translate }}</p>
            </li> -->

            <li class="active">
              <p *ngIf="!eligible" [routerLink]="['/application']" class="nav-link">
                <span>Step 5<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP6' | translate }}</span>
              </p>
              <a
                *ngIf="eligible"
                href="javascript:void(0)"
                class="nav-link"
                title="You Already Have an Application In Progress"
                ><span> Step 5<br />{{ 'DASHBOARD.APPLICATIONPROCESS.STEP8' | translate }} </span>
              </a>
            </li>
          </ul>
          <!-- <b>*Customer who has completed Step 3 to Step 5 can submit online application to Cagamas after Step 2 - Customer Registration</b> -->
        </div>
        <p class="bnoted" style="white-space: pre-line">{{ 'LANDING.APPLICATIONPROCESS.BNOTED' | translate }}</p>
      </div>
    </div>
  </section>
  <article class="main-top-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 col-12">
          <h2 class="heading mb-5">{{ 'LANDING.ABOUT.TITLE' | translate }}</h2>
          <p style="text-align: center; white-space: pre-line">{{ 'LANDING.ABOUT.DESCRIPTION' | translate }}</p>
          <!-- <p class="text-md-center text-left" style="text-align: justify">{{ 'LANDING.ABOUT.DESCRIPTION' | translate }}</p> -->

          <ul class="list">
            <li>{{ 'LANDING.ABOUT.LI1' | translate }}</li>
            <li class="text-md-justify text-left">{{ 'LANDING.ABOUT.LI2' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </article>

  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-12">
          <h2 class="heading text-center">{{ 'LANDING.FEATURES.TITLE' | translate }}</h2>
        </div>
      </div>
      <div class="card-list mt-4">
        <div class="row position-relative">
          <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-lg-0">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/lifetime.png" alt="" class="img-responsive" /></div>
              <h3>
                {{ 'LANDING.FEATURES.FEATURETITLE1' | translate }}
              </h3>
              <p>
                {{ 'LANDING.FEATURES.FEATUREDESCRIPTION1' | translate }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-lg-0">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/no repayment.png" alt="" class="img-responsive" /></div>
              <h3>
                {{ 'LANDING.FEATURES.FEATURETITLE2' | translate }}
              </h3>
              <p>
                {{ 'LANDING.FEATURES.FEATUREDESCRIPTION2' | translate }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-lg-0">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/residing.png" alt="" class="img-responsive" /></div>
              <h3>
                {{ 'LANDING.FEATURES.FEATURETITLE3' | translate }}
              </h3>
              <p>
                {{ 'LANDING.FEATURES.FEATUREDESCRIPTION3' | translate }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-lg-0">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/non-recourse.png" alt="" class="img-responsive" /></div>
              <h3>
                {{ 'LANDING.FEATURES.FEATURETITLE4' | translate }}
              </h3>
              <p>
                {{ 'LANDING.FEATURES.FEATUREDESCRIPTION4' | translate }}
              </p>
            </div>
          </div>
          <!-- <div class="col-lg-12 col-md-12 col-sm-6 mt-4">
            <div class="graphical-item">
              <div class="row">
                <div class="col-md-1">
                  <div class="image mt-2">
                    <img src="../../assets/images/non-recourse.png" alt="" class="img-responsive" />
                  </div>
                </div>
                <div class="col-md-2">
                  <h3 class="mt-4">{{ 'LANDING.FEATURES.FEATURETITLE4' | translate }}</h3>
                </div>
                <div class="col-md-9">
                  <p>{{ 'LANDING.FEATURES.FEATUREDESCRIPTION4' | translate }}</p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="bp-bg">
    <div class="para-general">
      <div class="container">
        <h2 class="heading text-center">{{ 'COMMON.EC' | translate }}</h2>
      </div>
    </div>

    <div class="card-list mt-5">
      <div class="container">
        <div class="row row-graphical">
          <div class="col-sm-6 col-xs-12 col-graphical mb-5">
            <div class="graphical-item">
              <div class="image">
                <img src="../../assets/images/eligible-criteria-borrower.png" alt="Borrower" class="img-responsive" />
              </div>
              <h3>{{ 'DASHBOARD.CRITERIA.BORROWERS.TITLE' | translate }}</h3>
              <ul class="list">
                <li>{{ 'DASHBOARD.CRITERIA.BORROWERS.LI1' | translate }}</li>
                <li>
                  {{ 'DASHBOARD.CRITERIA.BORROWERS.LI2' | translate }} <br />{{
                    'DASHBOARD.CRITERIA.BORROWERS.LI3' | translate
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12 col-graphical mb-5">
            <div class="graphical-item">
              <div class="image">
                <img src="../../assets/images/eligible-criteria-property.png" alt="Property" class="img-responsive" />
              </div>
              <h3>{{ 'DASHBOARD.CRITERIA.PROPERTY.TITLE' | translate }}</h3>
              <ul class="list">
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI1' | translate }}</li>
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI2' | translate }}</li>
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI3' | translate }}</li>
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI4' | translate }}</li>
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI5' | translate }}</li>
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI6' | translate }}</li>
                <li>{{ 'DASHBOARD.CRITERIA.PROPERTY.LI7' | translate }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5 bo-rn-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2 class="heading mt-4 mb-4">{{ 'DASHBOARD.CUSTOMER OBLIGATION.TITLE' | translate }}</h2>
          <ul class="list">
            <li>{{ 'DASHBOARD.CUSTOMER OBLIGATION.LI1' | translate }}</li>
            <li>{{ 'DASHBOARD.CUSTOMER OBLIGATION.LI2' | translate }}</li>
            <li>{{ 'DASHBOARD.CUSTOMER OBLIGATION.LI3' | translate }}</li>
            <li>{{ 'DASHBOARD.CUSTOMER OBLIGATION.LI4' | translate }}</li>
          </ul>
          <h2 class="heading mt-4 mb-4">{{ 'DASHBOARD.ROLEOFNOMINEE.TITLE' | translate }}</h2>
          <ul class="list">
            <li>{{ 'DASHBOARD.ROLEOFNOMINEE.LI1' | translate }}</li>
            <li>{{ 'DASHBOARD.ROLEOFNOMINEE.LI2' | translate }}</li>
            <li>{{ 'DASHBOARD.ROLEOFNOMINEE.LI3' | translate }}</li>
          </ul>
        </div>
        <div class="col-md-6">
          <img src="../../assets/images/ssb-get-peace.png" alt="" class="img-responsive" />
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Body End-->

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
