import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/auth/user.service';
import { CredentialsService } from '@app/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private credentialsService: CredentialsService,
    private userservice: UserService
  ) {}

  ngOnInit(): void {
    this.validatetoken();
  }
  switch(language: any) {
    /** For Malashiyan */
    console.log(language);
    localStorage.setItem('language', language);
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
  }
  validatetoken() {
    setTimeout(() => {
      const token = this.credentialsService.credentials?.token;
      if (token != null) {
        this.userservice.validate().subscribe(
          (res) => {
            console.log(res);
          },
          (error) => {
            if (error.statusText == 'OK') {
              this.router.navigate(['/dashboard']);
            }
          }
        );
      }
    }, 12000);
  }
}
