import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { UserService } from '@app/auth/user.service';
import { ThisReceiver } from '@angular/compiler';
@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.scss'],
})
export class ApplicationViewComponent implements OnInit {
  user: any;
  UserDocumentList: any;
  DocumentName: any = [];
  constructor(
    private userservice: UserService,
    public activatedRoute: ActivatedRoute,
    private _route: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.user = this.location.getState();
    console.log(this.user);
    this.getdocumentlist();
  }
  getdocumentlist = async () => {
    var params = {
      applicationId: this.user.applicationId,
    };
    await this.userservice.getUserDocumentList(this.user.applicationId).subscribe(
      (responce) => {
        this.UserDocumentList = responce;
        this.DocumentName = [];
        this.UserDocumentList.forEach((item: any) => {
          const myArray = item.documentName.split('/');
          const word = myArray[1].split('.');
          this.DocumentName.push(word[0]);
        });
        // this.UserList = responce;
        // this.allapplicationlist = [];
        // this.UserList.forEach((item: any) => {
        //   this.allapplicationlist.push(item.applicationId);
        // });
      },
      (err) => {
        //console.log(err);
      }
    );
  };
  Downloadfile(documentname: any, applicationId: any) {
    this.userservice.downloaddocument(documentname, applicationId);
  }
}
