<!-- Application Form -->
<section class="application main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <h2 class="heading mt-3">SKIM SARAAN BERCAGAR APPLICATION FORM</h2>
          </div>
          <div class="col-md-3 d-flex justify-content-md-end align-items-center">
            <div class="application-logo-text">
              <h3>Strictly Confidential</h3>
              <h5>OPE/SSB/FRM/001/v11</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="application_form col-12">
        <form>
          <div class="application_joint mb-4 mt-3 form-one-line-hld">
            <div class="form-one-line">
              <p class="mb-2">Joint Application (Y/N)</p>
              <div class="form-group">
                <div *ngIf="user.jointApplicant == 'Y'" class="form-check form-check-inline">
                  <input type="radio" value="Y" checked class="form-check-input" id="yes" disabled />
                  <label for="yes">Yes</label>
                </div>
                <div *ngIf="user.jointApplicant == 'N'" class="form-check form-check-inline">
                  <input type="radio" value="N" checked class="form-check-input" id="no" disabled />
                  <label for="no">No</label>
                </div>
              </div>
            </div>
            <div *ngIf="user.hasOwnProperty('applicationType')" class="form-one-line">
              <div class="form-group type">
                <p class="mb-2">Type</p>
                <div *ngIf="user.applicationType == 'Conventional'" class="form-check form-check-inline">
                  <input
                    type="radio"
                    value="Conventional"
                    checked
                    class="form-check-input"
                    disabled
                    id="Conventional"
                  />
                  <label for="Conventional">Conventional</label>
                </div>
                <div *ngIf="user.applicationType == 'Islamic'" class="form-check form-check-inline">
                  <input type="radio" value="Islamic" checked class="form-check-input" disabled id="Islamic" />
                  <label for="Islamic">Islamic</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="form-blue-bg mb-5">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="para-title">PARTICULAR OF APPLICANT</h4>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="">Salutation : </label>
                    <select id="salutation " class="form-control" disabled>
                      <option value="{{ user?.salutation }}" selected>{{ user?.salutation }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Name as per NRIC : </label>
                    <input type="text" value="{{ user?.nameAsPerIc }}" disabled class="form-control" id="name" />
                  </div>

                  <div class="form-group mb-4">
                    <label for="nric">NRIC No. : </label>
                    <input class="form-control" value="{{ user?.nricNumber }}" type="text" disabled id="nric" />
                  </div>

                  <div class="form-group mb-4">
                    <label for="address">Residential Address : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.residentialAddress }}"
                      disabled
                      id="address"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="post">Postcodes : </label>
                    <input
                      class="form-control"
                      value="{{ user.postCode != 0 ? user.postCode : '' }}"
                      disabled
                      type="text"
                      id="post"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="email">Email : </label>
                    <input
                      type="email"
                      class="form-control"
                      value="{{ user?.customerRegisteredEmailId }}"
                      disabled
                      id="email"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="tel">Telephone No. (Residence) : </label>
                    <input
                      type="number"
                      class="form-control"
                      value="{{
                        user.hasOwnProperty('resPhoneNumber') && user.resPhoneNumber != '0' ? user?.resPhoneNumber : ''
                      }}"
                      disabled
                      id="tel"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="telNo">Telephone No (H/P) : </label>
                    <input class="form-control" value="{{ user?.handPhoneNumber }}" type="text" disabled id="telNo" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="dob_box form-group mb-4">
                    <label for="birthday">Date of Birth :</label>
                    <input class="form-control" value="{{ user?.dateOfBirth }}" disabled type="text" id="dob" />
                  </div>

                  <div class="form-group mb-4">
                    <label for="Race">Race : </label>
                    <select id="Race" class="form-control" disabled>
                      <option value="{{ user?.race }}" selected>{{ user?.race }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="nationality">Nationality : </label>
                    <select id="nationality" class="form-control" disabled>
                      <option value="{{ user?.nationality }}" selected>{{ user?.nationality }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="" class="d-block">Sex : </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        name="male"
                        id="male"
                        checked
                        class="form-check-input"
                        value="{{ user?.sex }}"
                        disabled
                      />
                      <label for="male" class="mt-1 mx-2">{{ user?.sex }} </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="marital">Marital Status : </label>
                    <select id="marital" class="form-control" disabled>
                      <option value="{{ user?.maritalStatus }}">{{ user?.maritalStatus }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="no">No of Dependent(s): </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      value="{{ user?.noOfDependantsAndAges?.noOfDependants }}"
                      id="no"
                    />
                  </div>

                  <div class="form-group mb-4 form-line5">
                    <label for="name">Age(s) : </label>
                    <div class="form-line5-inn">
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no5"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant1 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no1"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant2 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no2"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant3 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no3"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant4 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no4"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant5 }}"
                      />
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="phouse">Present House : </label>
                    <select id="phouse" class="form-control" disabled>
                      >
                      <option value="{{ user?.presentHouse }}" selected>{{ user?.presentHouse }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12 my-4">
                  <hr />
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="occupation">Occupation : </label>
                    <input type="text" value="{{ user?.occupation }}" id="occupation" disabled class="form-control" />
                  </div>
                </div>
                <div class="col-md-6"></div>

                <div class="col-md-12">
                  <p>*Previous occupation, if retired/ unemployed</p>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="emp">Name of Employer : </label>
                    <input type="text" class="form-control" disabled value="{{ user?.employerName }}" id="emp" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="address">Address of Employer : </label>
                    <input type="text" class="form-control" disabled value="{{ user?.employerAddress }}" id="address" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="post">Postcode : </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      value="{{ user.employerPostCode != 0 ? user.employerPostCode : '' }}"
                      id="post"
                    />
                  </div>
                </div>
                <div class="col-md-12 my-4">
                  <hr />
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="occupation">Purpose of SSB application : </label>
                    <input
                      type="text"
                      value="{{ user?.ssbApplicationPurpose }}"
                      id="occupation"
                      disabled
                      class="form-control"
                    />
                  </div>
                </div>
                <div *ngIf="user.hasOwnProperty('ssbPayoutOption')" class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="occupation">Purpose of SSB application : </label>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            checked
                            id="monthly"
                            class="form-check-input"
                            value="{{ user?.ssbPayoutOption }}"
                            disabled
                          />
                          <label for="monthly" class="mt-1 mx-2">{{ user?.ssbPayoutOption }} </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <label for="" class="control-label">Usage of Lump Sum : </label>
                </div>

                <div class="col-md-8">
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="user.hasOwnProperty('lumSumUsage') && user.lumSumUsage != ''"
                      type="radio"
                      checked
                      id="medical"
                      class="form-check-input"
                      disabled
                      value="{{ user?.lumSumUsage }}"
                    />
                    <label
                      *ngIf="user.hasOwnProperty('lumSumUsage') && user.lumSumUsage != ''"
                      class="mt-1 mx-2"
                      for="medical"
                      >{{ user?.lumSumUsage }}</label
                    >
                  </div>

                  <!-- <div>
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    * If Lump Sum option is chosen, supporting documents will be needed. Please refer to the list
                    of documents to be enclosed at the end of this application form.
                  </small>
                </div> -->
                </div>

                <div class="col-md-4">
                  <label for="" class="control-label">Payment of Initial Costs & Expenses : </label>
                </div>

                <div *ngIf="user.hasOwnProperty('initialPayment')" class="col-md-8">
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      checked
                      id="applicant"
                      disabled
                      class="form-check-input"
                      value="{{ user?.initialPayment }}"
                    />
                    <label class="mt-1 mx-2" for="applicant">{{ user?.initialPayment }}</label
                    ><br />
                  </div>
                </div>

                <div class="col-md-12 my-4">
                  <hr />
                </div>
                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-12">
                  <h4 class="para-title">PARTICULAR OF JOINT APPLICANT</h4>
                </div>
                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="">Salutation : </label>
                    <select id="salutation " class="form-control" disabled>
                      <option value="{{ user?.jointApplicantDetails?.jointApplicantSalutation }}" selected>
                        {{ user?.jointApplicantDetails?.jointApplicantSalutation }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Name as per NRIC : </label>
                    <input
                      type="text"
                      value="{{ user?.jointApplicantDetails?.jointApplicantNameAsPerIc }}"
                      disabled
                      class="form-control"
                      id="name"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="nric">NRIC No. : </label>
                    <input
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantNricNumber }}"
                      type="text"
                      disabled
                      id="nric"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="address">Residential Address : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantResidentialAddress }}"
                      disabled
                      id="address"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="post">Postcode : </label>
                    <input
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantPostCode }}"
                      disabled
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false"
                      id="post"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="email">Email : </label>
                    <input
                      type="email"
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantEmail }}"
                      disabled
                      id="email"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="tel">Telephone No. (Residence) : </label>
                    <input
                      type="number"
                      class="form-control"
                      value="{{
                        user.hasOwnProperty('jointApplicantDetails') &&
                        user.jointApplicantDetails.jointApplicantResPhoneNumber != ''
                          ? user?.jointApplicantDetails?.jointApplicantResPhoneNumber
                          : ''
                      }}"
                      disabled
                      id="tel"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="telNo">Telephone No (H/P) : </label>
                    <input
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantHandPhoneNumber }}"
                      type="text"
                      disabled
                      id="telNo"
                    />
                  </div>
                </div>

                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-6">
                  <div class="dob_box form-group mb-4">
                    <label for="birthday">Date of Birth :</label>
                    <input
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantDateOfBirth }}"
                      disabled
                      type="text"
                      id="dob"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="Race">Race : </label>
                    <select id="Race" class="form-control" disabled>
                      <option value="{{ user?.jointApplicantDetails?.jointApplicantRace }}" selected>
                        {{ user?.jointApplicantDetails?.jointApplicantRace }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="nationality">Nationality : </label>
                    <select id="nationality" class="form-control" disabled>
                      <option value="{{ user?.jointApplicantDetails?.jointApplicantNationality }}" selected>
                        {{ user?.jointApplicantDetails?.jointApplicantNationality }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="" class="d-block">Sex : </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="male"
                        checked=""
                        disabled
                        class="form-check-input"
                        value="{{ user?.jointApplicantDetails?.jointApplicantSex }}"
                      />
                      <label for="{{ user?.jointApplicantDetails?.jointApplicantSex }}" class="mt-1 mx-2"
                        >{{ user?.jointApplicantDetails?.jointApplicantSex }}
                      </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="marital">Marital Status : </label>
                    <select id="marital" class="form-control" disabled>
                      <option value="{{ user?.jointApplicantDetails?.jointApplicantMaritalStatus }}">
                        {{ user?.jointApplicantDetails?.jointApplicantMaritalStatus }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Relationship">Relationship with Applicant : </label>
                    <select id="Relationship" class="form-control" disabled>
                      <option value="{{ user?.jointApplicantDetails?.jointApplicantRelationshipWithApplicant }}">
                        {{ user?.jointApplicantDetails?.jointApplicantRelationshipWithApplicant }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="form-group mb-4">
                    <label for="no">No of Dependent(s): </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      value="{{ user?.noOfDependantsAndAges?.noOfDependants }}"
                      id="no"
                    />
                  </div> -->

                  <!-- <div class="form-group mb-4 form-line5">
                    <label for="name">Age(s) : </label>
                    <div class="form-line5-inn">
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no5"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant1 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no1"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant2 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no2"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant3 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no3"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant4 }}"
                      />
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        id="no4"
                        value="{{ user?.noOfDependantsAndAges?.agesOfDependants?.ageOfDependant5 }}"
                      />
                    </div>
                  </div> -->

                  <!-- <div class="form-group mb-4">
                    <label for="phouse">Present House : </label>
                    <select id="phouse" class="form-control" disabled>
                      <option value="Own">Own</option>
                      <option value="{{ user?.presentHouse }}" selected>{{ user?.presentHouse }}</option>
                    </select>
                  </div> -->
                </div>

                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-12 my-4">
                  <hr />
                </div>

                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="occupation">Occupation : </label>
                    <input
                      type="text"
                      value="{{ user?.jointApplicantDetails?.jointApplicantOccupation }}"
                      id="occupation"
                      disabled
                      class="form-control"
                    />
                  </div>
                </div>
                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-6"></div>

                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-12">
                  <p>*Previous occupation, if retired/ unemployed</p>
                </div>

                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="emp">Name of Employer : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.jointApplicantDetails?.jointApplicantEmployerName }}"
                      disabled
                      value="Employer Name"
                      id="emp"
                    />
                  </div>
                </div>
                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="address">Address of Employer : </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      value="{{ user?.jointApplicantDetails?.jointApplicantEmployerAddress }}"
                      id="address"
                    />
                  </div>
                </div>
                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="post">Postcode : </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      value="{{
                        user?.jointApplicantDetails?.jointApplicantEmployerPostCode != 0
                          ? user?.jointApplicantDetails?.jointApplicantEmployerPostCode
                          : ''
                      }}"
                      id="post"
                    />
                  </div>
                </div>

                <div *ngIf="user.jointApplicant == 'Y'" class="col-md-12 my-4">
                  <hr />
                </div>

                <div class="col-md-12 pt-4">
                  <h4 class="para-title">APPLICANT’S BANKING ACCOUNT NUMBER</h4>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="bank">Name of Bank : </label>
                    <select id="bank" class="form-control" disabled>
                      <option value="{{ user?.bankDetails?.bankName }}" selected>
                        {{ user?.bankDetails?.bankName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="">Account Type : </label>
                    <select id="account" class="form-control" disabled>
                      <option value="{{ user?.bankDetails?.accountType }}" selected>
                        {{ user?.bankDetails?.accountType }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-4">
                    <label for="ac_no">Account Number : </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      value="{{ user?.bankDetails?.accountNumber }}"
                      id="ac_no"
                    />
                  </div>
                </div>

                <div class="col-md-12 pt-4">
                  <h4 class="para-title">PROPERTY INFORMATION</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="property_type">Property Type : </label>
                    <select id="property_type" class="form-control" disabled>
                      <option value="{{ user?.propertyInformation?.propertyType }}" selected>
                        {{ user?.propertyInformation?.propertyType }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="property_address">Property address : </label>propertyAddress
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.propertyInformation?.propertyAddress }}"
                      disabled
                      id="property_address"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="property_postcode">Postcode : </label>
                    <input
                      class="form-control"
                      value="{{ user?.propertyInformation?.postCodeInPropertyInfo }}"
                      type="text"
                      disabled
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Indicative Market Value : </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        class="form-control"
                        type="text"
                        disabled
                        value="{{ user?.propertyInformation?.indicativeMarketValue }}"
                        id="market_value"
                      />
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="valuation">Valuation Date :</label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.propertyInformation?.valuationDate }}"
                      disabled
                      id="valuation"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="expectation_market_value">Applicant Expected Market Value : </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        type="text"
                        value="{{ user?.propertyInformation?.expectedMarketValue }}"
                        class="form-control"
                        disabled
                        id="expectation_market_value"
                      />
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Sale & Purchase Price : </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value="{{ user?.propertyInformation?.saleAndPurchasePrice }}"
                        id="value"
                      />
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="sale_purchase_date">S & P Date :</label>
                    <input
                      type="text"
                      value="{{ user?.propertyInformation?.sandPDate }}"
                      class="form-control"
                      disabled
                      id="sale_purchase_date"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="tenure">Tenure of Property Title : </label>
                    <select id="tenure" class="form-control" disabled>
                      <option value="{{ user?.propertyInformation?.tentureOfPropertyTitle }}" selected>
                        {{ user?.propertyInformation?.tentureOfPropertyTitle }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="date">(Expiry Date of Lease) :</label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.propertyInformation?.leaseExpiryDate }}"
                      disabled
                      id="valuation"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="build_area">Build up area (in sq) : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.propertyInformation?.buildUpArea }}"
                      disabled
                      id="build_area"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="land_area">Land area (in sq) : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.propertyInformation?.landArea }}"
                      disabled
                      id="land_area"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label class="d-block" for="">Property encumbered : </label>
                    <div
                      *ngIf="
                        user.hasOwnProperty('propertyInformation') && user.propertyInformation.propertyEncumbered != ''
                      "
                      class="form-check form-check-inline"
                    >
                      <input
                        type="radio"
                        checked
                        id="yes"
                        disabled
                        class="form-check-input"
                        value="{{ user?.propertyInformation?.propertyEncumbered }}"
                      />
                      <label for="yes" class="mt-1 mx-2">{{ user?.propertyInformation?.propertyEncumbered }} </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Name of Bank : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.propertyInformation?.nameOfBankInPropertyInfo }}"
                      disabled
                      id="bank"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Estimated Outstanding Balance : </label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.propertyInformation?.estimatedOutstandingBalance }}"
                      disabled
                      id="address"
                    />
                  </div>

                  <div
                    *ngIf="
                      user.hasOwnProperty('propertyInformation') &&
                      user.propertyInformation.hasOwnProperty('fireAndHomeInsurancePolicy')
                    "
                    class="form-group mb-4"
                  >
                    <label for="" class="d-block">Fire & Home Insurance policy (or its equivalent policy) : </label>
                    <div class="form-check form-check-inline">
                      <input
                        checked
                        type="radio"
                        id="fyes"
                        disabled
                        class="form-check-input"
                        value="{{ user?.propertyInformation?.fireAndHomeInsurancePolicy }}"
                      />
                      <label for="yes" class="mt-1 mx-2"
                        >{{ user?.propertyInformation?.fireAndHomeInsurancePolicy }}
                      </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="insurer_name">Insurer Name : </label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.propertyInformation?.insurerName }} "
                      disabled
                      id="insurer_name"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="period_validity">Period Validity : </label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.propertyInformation?.periodValidity }}"
                      disabled
                      id="period_validity"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="" class="d-block">Renewal of Fire & Home Insurance : </label>
                    <div
                      *ngIf="
                        user.hasOwnProperty('propertyInformation') &&
                        user.propertyInformation.renewalOfFireAndHomeInsurance != ''
                      "
                      class="form-check form-check-inline"
                    >
                      <input
                        type="radio"
                        checked
                        id="yes3"
                        disabled
                        class="form-check-input"
                        value="{{ user?.propertyInformation?.renewalOfFireAndHomeInsurance }}"
                      />
                      <label for="yes3" class="mt-1 mx-2"
                        >{{ user?.propertyInformation?.renewalOfFireAndHomeInsurance }}
                      </label>
                    </div>
                  </div>
                </div>

                <!-- Upload Page -->
                <div class="col-md-12 pt-5">
                  <h4 class="para-title">PARTICULAR OF NOMINEE (1)</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="">Salutation : </label>
                    <select id="salutation " class="form-control" disabled>
                      <option value="{{ user?.nomineeDetails?.nominee1Details?.nominee1Salutation }}" selected>
                        {{ user?.nomineeDetails?.nominee1Details?.nominee1Salutation }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Name as per NRIC : </label>
                    <input
                      type="text"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1NameAsPerNric }}"
                      class="form-control"
                      id="name"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="nric">NRIC No. : </label>
                    <input
                      class="form-control"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1NricNumber }}"
                      type="text"
                      id="nric"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="address">Residential Address : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1ResidentailAddress }}"
                      disabled
                      id="address"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="post">Postcode : </label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1PostCode }}"
                      disabled
                      id="post"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="email">Email : </label>
                    <input
                      type="email"
                      class="form-control"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1Email }}"
                      id="email"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="tel">Telephone No. (Residence) : </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1ResPhoneNo }}"
                      id="tel"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="telNo">Telephone No (H/P) : </label>
                    <input
                      class="form-control"
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1HandPhoneNo }}"
                      type="text"
                      disabled
                      id="telNo"
                    />
                  </div>

                  <div class="dob_box form-group mb-4">
                    <label for="birthday">Date of Birth :</label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1DateOfBirth }}"
                      id="dob"
                      disabled
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="" class="d-block">Sex : </label>
                    <div
                      *ngIf="
                        user.hasOwnProperty('nomineeDetails') && user.nomineeDetails.nominee1Details.nominee1Sex != ''
                      "
                      class="form-check form-check-inline"
                    >
                      <input
                        type="radio"
                        id="male"
                        checked
                        disabled
                        class="form-check-input"
                        value="{{ user?.nomineeDetails?.nominee1Details?.nominee1Sex }}"
                      />
                      <label for="male" class="mt-1 mx-2"
                        >{{ user?.nomineeDetails?.nominee1Details?.nominee1Sex }}
                      </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="Race">Race : </label>
                    <select id="Race" class="form-control" disabled>
                      <option value="{{ user?.nomineeDetails?.nominee1Details?.nominee1Race }}" selected>
                        {{ user?.nomineeDetails?.nominee1Details?.nominee1Race }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="nationality">Nationality : </label>
                    <select disabled id="nationality" class="form-control">
                      <option value="{{ user?.nomineeDetails?.nominee1Details?.nominee1Nationality }}" selected>
                        {{ user?.nomineeDetails?.nominee1Details?.nominee1Nationality }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-4">
                    <label for="marital">Marital Status : </label>
                    <select disabled id="marital" class="form-control">
                      <option value="{{ user?.nomineeDetails?.nominee1Details?.nominee1MaritalStatus }}" selected>
                        {{ user?.nomineeDetails?.nominee1Details?.nominee1MaritalStatus }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-4">
                    <label for="occupation">Relationship : </label>
                    <input
                      type="text"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee1Details?.nominee1Relationship }}"
                      id="relationship"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-12 pt-5">
                  <h4 class="para-title">PARTICULAR OF NOMINIEE (2)</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="">Salutation : </label>
                    <select id="salutation " class="form-control" disabled>
                      <option value="{{ user?.nomineeDetails?.nominee2Details?.nominee2Salutation }}" selected>
                        {{ user?.nomineeDetails?.nominee2Details?.nominee2Salutation }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name">Name as per NRIC : </label>
                    <input
                      type="text"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2NameAsPerNric }}"
                      class="form-control"
                      id="name"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="nric">NRIC No. : </label>
                    <input
                      class="form-control"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2NricNumber }}"
                      type="text"
                      id="nric"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="address">Residential Address : </label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2ResidentailAddress }}"
                      disabled
                      id="address"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="post">Postcode : </label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2PostCode }}"
                      disabled
                      id="post"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="email">Email : </label>
                    <input
                      type="email"
                      class="form-control"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2Email }}"
                      id="email"
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="tel">Telephone No. (Residence) : </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2ResPhoneNo }}"
                      id="tel"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="telNo">Telephone No (H/P) : </label>
                    <input
                      class="form-control"
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2HandPhoneNo }}"
                      type="text"
                      disabled
                      id="telNo"
                    />
                  </div>

                  <div class="dob_box form-group mb-4">
                    <label for="birthday">Date of Birth :</label>
                    <input
                      class="form-control"
                      type="text"
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2DateOfBirth }}"
                      id="dob"
                      disabled
                    />
                  </div>

                  <div class="form-group mb-4">
                    <label for="" class="d-block">Sex : </label>
                    <div
                      *ngIf="
                        user.hasOwnProperty('nomineeDetails') && user.nomineeDetails.nominee2Details.nominee2Sex != ''
                      "
                      class="form-check form-check-inline"
                    >
                      <input
                        type="radio"
                        id="male"
                        checked
                        disabled
                        class="form-check-input"
                        value="{{ user?.nomineeDetails?.nominee2Details?.nominee2Sex }}"
                      />
                      <label for="male" class="mt-1 mx-2"
                        >{{ user?.nomineeDetails?.nominee2Details?.nominee2Sex }}
                      </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="Race">Race : </label>
                    <select id="Race" class="form-control" disabled>
                      <option value="{{ user?.nomineeDetails?.nominee2Details?.nominee2Race }}" selected>
                        {{ user?.nomineeDetails?.nominee2Details?.nominee2Race }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="nationality">Nationality : </label>
                    <select disabled id="nationality" class="form-control">
                      <option value="{{ user?.nomineeDetails?.nominee2Details?.nominee2Nationality }}" selected>
                        {{ user?.nomineeDetails?.nominee2Details?.nominee2Nationality }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-4">
                    <label for="marital2">Marital Status : </label>
                    <select disabled id="marital2" class="form-control">
                      <option value="{{ user?.nomineeDetails?.nominee2Details?.nominee2MaritalStatus }}" selected>
                        {{ user?.nomineeDetails?.nominee2Details?.nominee2MaritalStatus }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-4">
                    <label for="occupation">Relationship : </label>
                    <input
                      type="text"
                      disabled
                      value="{{ user?.nomineeDetails?.nominee2Details?.nominee2Relationship }}"
                      id="relationship"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-12 pt-4">
                  <h4 class="para-title">Upload The List of Documents</h4>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-1"><span class="bul-num">1.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label">a) A photocopy of MyKad of the homeowner </label>
                        <!-- <input type="file" id="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('myKadHomeOwner', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('myKadHomeOwner') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download photocopy of MyKad of the homeowner
                        </button>
                      </div>

                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >b) A photocopy of MyKad of the <strong>Joint Owner</strong>
                        </label>
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('myKadJointOwner', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('myKadJointOwner') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download photocopy of MyKad of the Joint Owner
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">2.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          ><strong>For Joint Applicant</strong>, a copy of
                          <strong>Marriage Certification or Birth Certification</strong></label
                        >
                        <!-- <input type="file" id="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('marriageCertificate', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('marriageCertificate') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Marriage Certification
                        </button>
                      </div>

                      <div class="form-group mb-4">
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('birthCertificate', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('birthCertificate') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Birth Certification
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">3.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >A copy of the
                          <strong>Land Title/Strata Title / Sale & Purchase Agreement / Deed of Assignment</strong>
                          (<strong>MANDATORY</strong> to upload <strong>AT LEAST 1 document</strong>)</label
                        >
                        <!-- <input type="file" id="file" disabled class="form-control" /> -->
                        <!-- <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('landTileOrStrataTitle', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('landTileOrStrataTitle') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Land Title/Strata Title
                        </button>
                      </div>

                      <div class="form-group mb-4">
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('saleAndPurchaseAgreement', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('saleAndPurchaseAgreement') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Sale & Purchase Agreement
                        </button>
                      </div>

                      <!-- 2 -->
                      <div class="form-group mb-4">
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('deedOfAssignment', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('deedOfAssignment') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Deed of Assignment
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">4.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label"
                          >A copy of <strong>AKPK’s confirmation of attendance</strong> sheet
                        </label>
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('akpkCertificate', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('akpkCertificate') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of AKPK’s confirmation of attendance
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">5.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >5. A copy of the <strong>latest Receipts</strong> for <strong>Quit Rent,Assessment</strong>,
                          and <strong>Maintenance Fee</strong>(<strong>MANDATORY</strong> to upload
                          <strong>AT LEAST 2 documents</strong>)</label
                        >
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('receiptForQuitRent', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('receiptForQuitRent') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of latest Receipts for Quit Rent
                        </button>
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                      </div>

                      <!-- Charu Start -->
                      <div class="form-group mb-4">
                        <!-- <input type="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('receiptForAssesment', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('receiptForAssesment') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of latest Receipts for Assessment
                        </button>
                      </div>
                      <!-- 2 -->
                      <div class="form-group mb-4">
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('receiptForMaintenanceFee', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('receiptForMaintenanceFee') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of latest Receipts for Maintenance Fee
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Charu End -->
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-1"><span class="bul-num">6.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >A copy of <strong>Land Premium Receipt</strong> (for renewal of land lease up to minimum 90
                          years)</label
                        >
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('landPremiumReceipt', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('landPremiumReceipt') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Land Premium Receipt
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">7.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label">A copy of <strong>Recent Valuation Report</strong></label>
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('rentValuationReport', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('rentValuationReport') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Recent Valuation Report
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">8.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >A copy of <strong>Fire and Home Insurance/Takaful Policy</strong> or its equivalent
                          policy</label
                        >
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('fireAndHomeInsurancePolicyOrTakafulPolicy', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('fireAndHomeInsurancePolicyOrTakafulPolicy') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Fire and Home Insurance/Takaful Policy
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">9.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label"
                          >A copy of <strong>Central Credit Reference Information System (CCRIS) report</strong></label
                        >
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('ccrisReport', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('ccrisReport') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of Central Credit Reference Information System (CCRIS) report
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">10.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >Any of the following documents when <strong>Lump Sum Payment is requested:</strong> (<strong
                            >MANDATORY</strong
                          >
                          to upload <strong>EITHER ONE</strong> of the documents below)</label
                        >
                        <label for="" class="control-label"
                          >a) A confirmation letter from doctor/hospital on the required medical treatment (for payment
                          of medical expenses)</label
                        >
                        <!-- <input type="file" id="file" disabled class="form-control" />
                        <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('confirmationLetterFromDoctor', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('confirmationLetterFromDoctor') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of confirmation letter from doctor/hospital
                        </button>
                      </div>
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >b) A copy of the full redemption statement (for settlement of outstanding mortgage on the
                          property)</label
                        >
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('fullRedemptionStatement', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('fullRedemptionStatement') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of the full redemption statement
                        </button>
                      </div>

                      <!-- 2 -->
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >c) A copy of renovation quotation/invoices from contractor (for refurbishment and maintenance
                          expenses to upkeep the property)</label
                        >
                        <!-- <input type="file" disabled class="form-control" /> -->
                        <br />
                        <button
                          type="button"
                          (click)="Downloadfile('renovationQuotationOrInvoices', user.applicationId)"
                          [ngClass]="{ disabled: !DocumentName.includes('renovationQuotationOrInvoices') }"
                          class="btn btn-primary btn-custom btn-small mt-2"
                        >
                          Download copy of renovation quotation/invoices from contractor
                        </button>
                      </div>
                    </div>
                  </div>

                   <div class="row">
                      <div class="col-1"><span class="bul-num">11.</span></div>
                      <div class="col-11">
                        <div class="form-group mb-4">
                          <label for="" class="control-label">Acknowledgement Form to be signed by Nominee(s)</label>

                          <label for="" class="control-label"
                            >a) A copy of the Nominee(s) Acknowledgement Document</label
                          >
                          <!-- <input type="file" id="file" disabled class="form-control" />
                          <button type="button" class="btn btn-primary btn-custom btn-small mt-2">Download</button> -->
                          <br />
                          <button
                            type="button"
                            (click)="Downloadfile('nomineeAck', user.applicationId)"
                            [ngClass]="{ disabled: !DocumentName.includes('nomineeAck') }"
                            class="btn btn-primary btn-custom btn-small mt-2"
                          >
                            Download copy of Nominee Acknowledge Document
                          </button>
                        </div>
                        <div class="form-group mb-4">
                          <label for="" class="control-label"
                            >b) A copy of the Nominee(s) Acknowledge Additional Document</label
                          >
                          <!-- <input type="file" disabled class="form-control" /> -->
                          <br />
                          <button
                            type="button"
                            (click)="Downloadfile('nomineeAckTwo', user.applicationId)"
                            [ngClass]="{ disabled: !DocumentName.includes('nomineeAckTwo') }"
                            class="btn btn-primary btn-custom btn-small mt-2"
                          >
                            Download copy of Nominee Acknowledge Second Document
                          </button>
                        </div>  
                      </div>
                    </div>
                    
                </div>
              </div>

              <div class="m-3 text-center">
                <!-- <button class="btn btn-primary btn-custom" type="submit">
                  <i class="fas fa-cog fa-spin"></i>
                  <span translate>Next</span>
                </button> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>Copyright 2022 Cagamas Berhad (Reg. No. 198601008739 (157931-A)). All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
