import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verifyuser',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.scss'],
})
export class VerifyuserComponent implements OnInit {
  email = '';
  emailverify: any;
  queryParamsStatus: any;
  constructor(
    private verifyemailservice: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const productIdFromRoute = Number(routeParams.get('emailId'));

    console.log(productIdFromRoute);
    this.route.params.subscribe((params: Params) => {
      this.queryParamsStatus = params['emailId'];
      console.log(this.queryParamsStatus);
    });
    this.get_emailVerify();
  }

  get_emailVerify() {
    this.verifyemailservice.emailVerify(this.queryParamsStatus).subscribe(
      (response) => {
        console.log(response);
        if (response.error == 'success' || response.error == 'OK') {
          Swal.fire({ icon: 'success', title: 'Success...', text: '' });
          this.router.navigate(['/verified-successfully']);
        }
      },
      (error) => {
        if (error.statusText == 'OK') {
          Swal.fire({ icon: 'success', title: 'Success...', text: 'Account Activated Successfully. Please login' });
          this.router.navigate(['/verified-successfully']);
        } else {
          Swal.fire({ icon: 'error', title: 'Oops...', text: error.error.message });
        }
      }
    );
  }
}
