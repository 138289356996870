<div class="register-success">
  <img src="../../../assets/images/cagamas-logo-new01.jpg" alt="Cagamas" class="img-fluid logo" />
  <div class="register-success-inn">
    <h2>{{ 'VERIFYHEADER' | translate }}</h2>
    <img src="../../../assets/images/icon-agree.svg" alt="Cagamas Register Success" class="img-fluid iconImg" />
    <p>{{ 'VERIFYTEXT' | translate }}</p>
    <p>
      <a [routerLink]="['/login']" class="btn btn-custom">{{ 'VERIFYBUTTON' | translate }}</a>
    </p>
  </div>
</div>
