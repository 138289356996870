<!-- Body Start-->
<div class="main-wrapper">
  <!-- <div class="banner-wrapper first last"> -->
    <div class="row no-gutters m-0">
      <div class="col-md-6 col-12">
        <div class="container">
          <!-- <div class="page-title-wrap">
            <h1 class="page-title">{{ 'LANDING.BANNER.TITLE' | translate }}</h1>
            <div class="subtitle">{{ 'LANDING.BANNER.SUBTITLE' | translate }}</div>
          </div> -->
        </div>
      </div>
      <!-- <div class="col-md-6 col-12">
        <div class="banner-img">
          <img
            src="../../../assets/images/banner-1.png"
            width="800"
            height="385"
            alt="Eligibility Criteria"
            loading="lazy"
            class="image-style-wide"
          />
        </div>
      </div> -->
    </div>
  <!-- </div> -->

  <section class="py-5 ">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="heading mb-5 mt-4 text-center">{{ 'DASHBOARD.APPLICATIONPROCESS.TITLE' | translate }}</h2>

          <div class="banner-img" style="text-align: center;">
            <img
              src="../../../assets/images/success.png"
              width="185"
              height="185"
              alt="Eligibility Criteria"
              loading="lazy"
              class="image-style-wide"
            />
          </div>

          <h3 class="heading mb-5 mt-1 text-center">{{ 'APPLICATION.FORM3.SUCCESSAPP' | translate }}</h3>

          <div style="text-align: center;">
            <button
            class="btn btn-primary btn-custom mobile-wodth-100 mb-2 mb-md-0"
            (click)="submitForm()"
            type="submit" >
        
            <span translate>{{ 'AKPK.RESETBUTTON' | translate }}</span>
          </button>
  
          </div>
      
          
        </div>
      </div>
    </div>
  </section>
 

  
</div>

<!-- Body End-->

<!-- Footer Start-->
<!-- <footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer> -->
<!-- Footer End-->
