import { AbstractControl, FormGroup } from '@angular/forms';

export function NationalityValidators(controlName: AbstractControl): { [key: string]: any } | null {
  const control = controlName;

  if (control.value == '') {
    return null;
  }
  if (control.value !== 'Malaysian') {
    return { NationalityValidators: true };
  } else {
    return null;
  }
}
