import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { browserRefresh } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-verified-successfully',
  templateUrl: './verified-successfully.component.html',
  styleUrls: ['./verified-successfully.component.scss'],
})
export class VerifiedSuccessfullyComponent implements OnInit {
  message: string = '';
  constructor(public translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    if (browserRefresh == true) {
      this.router.navigate(['/']);
    }
  }
}
