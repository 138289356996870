import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ApplicationPartTwoComponent } from './application-part-two.component';
import { Shell } from '@app/shell/shell.service';
//const routes: Routes = [{ path: '', component: ApplicationPartTwoComponent }];
const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'application-part-two',
      component: ApplicationPartTwoComponent,
      data: { title: marker('Submit Application') },
    },
  ]),
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApplicationPartTwoRoutingModule {}
