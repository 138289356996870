import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import Swal from 'sweetalert2';
import { NationalityValidators } from '../../providers/NationalityValidators';
import { DatePipe, NumberFormatStyle } from '@angular/common';
import { EligibilityCheckService } from '@app/auth/eligibility--check.service';
import { browserRefresh } from '@app/app.component';
import { UserService } from '@app/auth/user.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-application-part-two',
  templateUrl: './application-part-two.component.html',
  styleUrls: ['./application-part-two.component.scss'],
})
export class ApplicationPartTwoComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  ageerror = false;
  applicForm!: FormGroup;
  application_type: any = localStorage.getItem('applcation_type');
  application_part_one_data = localStorage.getItem('part-one');
  joint_part = JSON.parse(this.application_part_one_data ? this.application_part_one_data : '');

  postcodeerror = false;
  propertypostcodeerror = false;
  userDetailData: any = [];
  applicationId: any;
  tryagain: string = '';
  constructor(
    private translate: TranslateService,
    private userservice: UserService,
    private eligibilityCheck: EligibilityCheckService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createForm();
    this.setUserleaseValidators();
    this.setbankValidators();
    this.setinsurerValidators();
    this.jointvalidators();
    this.usersubmittedapplication();
    // console.log("joint_part",this.joint_part);
  }

  ngOnInit(): void {
    this.translate.get('APPLICATION').subscribe((data: any) => {
      this.tryagain = data.FORM1.TRYAGAIN;
    });
    if (browserRefresh == true) {
      this.router.navigate(['/dashboard']);
    }
  }

  private createForm() {
    this.applicForm = this.formBuilder.group({
      salutation: [''],
      applicant_name: [''],
      nric_no: [''],
      res_address: [''],
      postcode: [''],
      email: [''],
      res_tel: [''],
      hp_tel: [''],
      dob: [''],
      race: [''],
      nationality: ['Malaysian'],
      sex: [''],
      marital_status: [''],
      relationship: [''],
      occupation: [''],
      name_of_employer: [''],
      address_of_employer: [''],
      postcode_of_employer: [''],
      name_of_bank: ['', Validators.required],
      account_type: ['', Validators.required],
      account_number: ['', Validators.required],
      account_type_con_isl: ['', Validators.required],
      property_type: ['', Validators.required],
      property_address: ['', Validators.required],
      property_postcode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      market_value: [''],
      valuation_date: [''],
      expectation_market_value: [''],
      sale_purchase_price: [''],
      sale_purchase_date: [''],
      property_tenure: ['', Validators.required],
      lease_expiry_date: [''],
      build_up_area: ['', Validators.required],
      land_area: ['', Validators.required],
      property_encumbered: ['', Validators.required],
      property_bank_name: [''],
      outstanding_balns: [''],
      fire_home_insurence: ['', Validators.required],
      insurer_name: [''],
      period_validity: [''],
      renewal_insurance_caga: [''],
      renewal_insurence: ['', Validators.required],
    });
  }

  jointvalidators() {
    if (this.application_type == 'Y' || this.application_type == 'yes') {
      this.applicForm.controls['applicant_name'].setValidators([Validators.required]);
      this.applicForm.controls['nric_no'].setValidators([
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
      ]);
      this.applicForm.controls['res_address'].setValidators([Validators.required]);
      this.applicForm.controls['postcode'].setValidators([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]);
      this.applicForm.controls['email'].setValidators([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]);
      this.applicForm.controls['hp_tel'].setValidators([Validators.required]);
      this.applicForm.controls['dob'].setValidators([Validators.required]);
      this.applicForm.controls['race'].setValidators([Validators.required]);
      this.applicForm.controls['nationality'].setValidators([Validators.required]);
      this.applicForm.controls['sex'].setValidators([Validators.required]);
      this.applicForm.controls['marital_status'].setValidators([Validators.required]);
      this.applicForm.controls['relationship'].setValidators([Validators.required]);
      this.applicForm.controls['occupation'].setValidators([Validators.required]);
    } else {
      this.applicForm.controls['applicant_name'].setValidators(null);
      this.applicForm.controls['nric_no'].setValidators(null);
      this.applicForm.controls['res_address'].setValidators(null);
      this.applicForm.controls['postcode'].setValidators(null);
      this.applicForm.controls['email'].setValidators(null);
      this.applicForm.controls['dob'].setValidators(null);
      this.applicForm.controls['race'].setValidators(null);
      this.applicForm.controls['nationality'].setValidators(null);
      this.formBuilder.group({ validator: NationalityValidators });
      this.applicForm.controls['sex'].setValidators(null);
      this.applicForm.controls['marital_status'].setValidators(null);
      this.applicForm.controls['relationship'].setValidators(null);
      this.applicForm.controls['occupation'].setValidators(null);
    }
    this.applicForm.controls['applicant_name'].updateValueAndValidity();
    this.applicForm.controls['nric_no'].updateValueAndValidity();
    this.applicForm.controls['res_address'].updateValueAndValidity();
    this.applicForm.controls['postcode'].updateValueAndValidity();
    this.applicForm.controls['email'].updateValueAndValidity();
    this.applicForm.controls['dob'].updateValueAndValidity();
    this.applicForm.controls['race'].updateValueAndValidity();
    this.applicForm.controls['nationality'].updateValueAndValidity();
    this.applicForm.controls['sex'].updateValueAndValidity();
    this.applicForm.controls['marital_status'].updateValueAndValidity();
    this.applicForm.controls['relationship'].updateValueAndValidity();
    this.applicForm.controls['occupation'].updateValueAndValidity();
  }

  setUserleaseValidators() {
    const institutionControl = this.applicForm.get('lease_expiry_date');
    this.applicForm.get('property_tenure')?.valueChanges.subscribe((userCategory) => {
      if (userCategory === 'Leasehold') {
        this.applicForm.controls['lease_expiry_date'].setValidators([Validators.required]);
      } else {
        institutionControl?.setValidators(null);
      }
      institutionControl?.updateValueAndValidity();
    });
  }
  numberformat(controlname: any) {
    if (controlname == 'market_value') {
      var numbervalue = this.applicForm.controls['market_value'].value;
      const fixedNumber = Number.parseFloat(numbervalue).toFixed(2);
      const newnumber = fixedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (controlname == 'market_value' && newnumber != 'NaN') {
        this.applicForm.controls['market_value'].setValue(newnumber);
      }
    } else if (controlname == 'outstanding_balns') {
      var numbervalue = this.applicForm.controls['outstanding_balns'].value;
      const fixedNumber: any = Number.parseFloat(numbervalue).toFixed(2);
      const newnumber = fixedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (controlname == 'outstanding_balns' && newnumber != 'NaN') {
        this.applicForm.controls['outstanding_balns'].setValue(newnumber);
      }
    } else if (controlname == 'expectation_market_value') {
      var numbervalue = this.applicForm.controls['expectation_market_value'].value;
      const fixedNumber = Number.parseFloat(numbervalue).toFixed(2);
      const newnumber = String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (controlname == 'expectation_market_value' && newnumber != 'NaN') {
        this.applicForm.controls['expectation_market_value'].setValue(newnumber);
      }
    }
    //**Charu Start */
    else if (controlname == 'market_value') {
      var numbervalue = this.applicForm.controls['market_value'].value;
      const fixedNumber = Number.parseFloat(numbervalue).toFixed(2);
      const newnumber = String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (controlname == 'market_value' && newnumber != 'NaN') {
        this.applicForm.controls['market_value'].setValue(newnumber);
      }
    }
    //**Charu End */
    else {
      var numbervalue = this.applicForm.controls['sale_purchase_price'].value;
      const fixedNumber = Number.parseFloat(numbervalue).toFixed(2);
      const newnumber = String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (controlname == 'sale_purchase_price' && newnumber != 'NaN') {
        this.applicForm.controls['sale_purchase_price'].setValue(newnumber);
      } else {
        var numbervalue = this.applicForm.controls['sale_purchase_price'].value;
        if (!numbervalue) {
          this.applicForm.controls['sale_purchase_price'].setValue('');
        } else {
          const fixedNumber = Number.parseFloat(numbervalue).toFixed(2);
          const newnumber = String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          if (controlname == 'sale_purchase_price' && newnumber != 'NaN') {
            this.applicForm.controls['sale_purchase_price'].setValue(newnumber);
          }
        }
      }
    }
  }
  setbankValidators() {
    const institutionControl = this.applicForm.get('property_bank_name');
    const fire_home_insurence = this.applicForm.get('outstanding_balns');
    this.applicForm.get('property_encumbered')?.valueChanges.subscribe((nackname) => {
      if (nackname === 'Yes') {
        this.applicForm.controls['property_bank_name'].setValidators([Validators.required]);
        this.applicForm.controls['outstanding_balns'].setValidators([Validators.required]);
      } else {
        institutionControl?.setValidators(null);
        fire_home_insurence?.setValidators(null);
      }
      institutionControl?.updateValueAndValidity();
      fire_home_insurence?.updateValueAndValidity();
    });
  }
  setinsurerValidators() {
    const institutionControl1 = this.applicForm.get('insurer_name');
    const fire_home_insurence1 = this.applicForm.get('period_validity');
    this.applicForm.get('fire_home_insurence')?.valueChanges.subscribe((nackname) => {
      if (nackname === 'In force') {
        this.applicForm.controls['insurer_name'].setValidators([Validators.required]);
        this.applicForm.controls['period_validity'].setValidators([Validators.required]);
      } else {
        institutionControl1?.setValidators(null);
        fire_home_insurence1?.setValidators(null);
      }
      institutionControl1?.updateValueAndValidity();
      fire_home_insurence1?.updateValueAndValidity();
    });
    this.applicForm.get('sale_purchase_price')?.valueChanges.subscribe((nackname) => {
      if (nackname != '') {
        this.applicForm.controls['sale_purchase_date'].setValidators([Validators.required]);
      } else {
        this.applicForm.controls['sale_purchase_date'].setValidators(null);
      }
      this.applicForm.controls['sale_purchase_date'].updateValueAndValidity();
    });
  }
  apkpk_one() {
    if (this.applicForm.valid) {
      //this.router.navigate(['/application-part-two']);
    } else {
      this.markFormGroupTouched(this.applicForm);
    }
  }
  post_code_validate() {
    this.eligibilityCheck.postcode(this.applicForm.value.postcode).subscribe(
      (data) => {},
      (error) => {
        if (error.status == 200) {
          this.postcodeerror = false;
        } else {
          this.applicForm.controls['postcode'].setValue('');
          this.postcodeerror = true;
        }
      }
    );
  }
  post_code_validate2() {
    this.eligibilityCheck.postcode(this.applicForm.value.property_postcode).subscribe(
      (data) => {},
      (error) => {
        if (error.status == 200) {
          this.propertypostcodeerror = false;
        } else {
          this.applicForm.controls['property_postcode'].setValue('');
          this.propertypostcodeerror = true;
        }
      }
    );
  }
  usersubmittedapplication = async () => {
    await this.userservice.getapplication().subscribe((res) => {
      const _res: any = res.filter((x: any) => {
        return x.orderStatus === 'DRAFT';
      });
      if (_res.length > 0) {
        this.userDetailData = _res;
        console.log(this.userDetailData, ' two this.userDetailData');
        if (this.userDetailData[0].jointApplicantDetails.hasOwnProperty('jointApplicantDateOfBirth')) {
          const myArray = this.userDetailData[0].jointApplicantDetails.jointApplicantDateOfBirth.split('/');
          const dobformatted = myArray[2] + '-' + myArray[1] + '-' + myArray[0];
          this.applicForm.controls['dob'].setValue(dobformatted);
        }
        // this.applicForm.controls ['dob'] .setValue(new Date(this.userDetailData[0].dateOfBirth)?.toISOString().substr(0, 10));
        this.applicForm.controls['email'].setValue(this.userDetailData[0].jointApplicantDetails?.jointApplicantEmail);
        this.applicForm.controls['address_of_employer'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantEmployerAddress
        );
        this.applicForm.controls['name_of_employer'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantEmployerName
        );
        if (
          this.userDetailData[0].jointApplicantDetails.hasOwnProperty('jointApplicantEmployerPostCode') &&
          this.userDetailData[0].jointApplicantDetails.jointApplicantEmployerPostCode != 0
        ) {
          this.applicForm.controls['postcode_of_employer'].setValue(
            this.userDetailData[0].jointApplicantDetails?.jointApplicantEmployerPostCode
          );
        }
        if (
          this.userDetailData[0].jointApplicantDetails.hasOwnProperty('jointApplicantHandPhoneNumber') &&
          this.userDetailData[0].jointApplicantDetails.jointApplicantHandPhoneNumber != 0
        ) {
          this.applicForm.controls['hp_tel'].setValue(
            this.userDetailData[0].jointApplicantDetails?.jointApplicantHandPhoneNumber
          );
        }
        this.applicForm.controls['marital_status'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantMaritalStatus
        );
        this.applicForm.controls['applicant_name'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantNameAsPerIc
        );
        //this.applicForm.controls ['nationality'] .setValue(this.userDetailData[0].jointApplicantDetails?.jointApplicantNationality)
        this.applicForm.controls['nric_no'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantNricNumber
        );
        this.applicForm.controls['occupation'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantOccupation
        );
        if (
          this.userDetailData[0].jointApplicantDetails.hasOwnProperty('jointApplicantPostCode') &&
          this.userDetailData[0].jointApplicantDetails.jointApplicantPostCode != 0
        ) {
          this.applicForm.controls['postcode'].setValue(
            this.userDetailData[0].jointApplicantDetails?.jointApplicantPostCode
          );
        }
        this.applicForm.controls['race'].setValue(this.userDetailData[0].jointApplicantDetails?.jointApplicantRace);
        this.applicForm.controls['relationship'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantRelationshipWithApplicant
        );
        if (
          this.userDetailData[0].jointApplicantDetails.hasOwnProperty('jointApplicantResPhoneNumber') &&
          this.userDetailData[0].jointApplicantDetails.jointApplicantResPhoneNumber != 0
        ) {
          this.applicForm.controls['res_tel'].setValue(
            this.userDetailData[0].jointApplicantDetails?.jointApplicantResPhoneNumber
          );
        }
        this.applicForm.controls['res_address'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantResidentialAddress
        );
        this.applicForm.controls['salutation'].setValue(
          this.userDetailData[0].jointApplicantDetails?.jointApplicantSalutation
        );
        this.applicForm.controls['sex'].setValue(this.userDetailData[0].jointApplicantDetails?.jointApplicantSex);

        this.applicForm.controls['account_number'].setValue(this.userDetailData[0].bankDetails?.accountNumber);
        this.applicForm.controls['account_type'].setValue(this.userDetailData[0].bankDetails?.accountType);
        this.applicForm.controls['name_of_bank'].setValue(this.userDetailData[0].bankDetails?.bankName);
        this.applicForm.controls['account_type_con_isl'].setValue(
          this.userDetailData[0].bankDetails?.accountTypeConIsl
        );

        this.applicForm.controls['build_up_area'].setValue(this.userDetailData[0].propertyInformation?.buildUpArea);
        this.applicForm.controls['outstanding_balns'].setValue(
          this.userDetailData[0].propertyInformation?.estimatedOutstandingBalance
        );
        this.applicForm.controls['expectation_market_value'].setValue(
          this.userDetailData[0].propertyInformation?.expectedMarketValue
        );
        this.applicForm.controls['market_value'].setValue(
          this.userDetailData[0].propertyInformation?.indicativeMarketValue
        );
        this.applicForm.controls['fire_home_insurence'].setValue(
          this.userDetailData[0].propertyInformation?.fireAndHomeInsurancePolicy
        );
        this.applicForm.controls['outstanding_balns'].setValue(
          this.userDetailData[0].propertyInformation?.estimatedOutstandingBalance
        );
        this.applicForm.controls['land_area'].setValue(this.userDetailData[0].propertyInformation?.landArea);
        this.applicForm.controls['insurer_name'].setValue(this.userDetailData[0].propertyInformation?.insurerName);
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('leaseExpiryDate')) {
          const myArray = this.userDetailData[0].propertyInformation.leaseExpiryDate.split('/');
          const leaseExpiryDateformatted = myArray[2] + '-' + myArray[1] + '-' + myArray[0];
          this.applicForm.controls['lease_expiry_date'].setValue(leaseExpiryDateformatted);
        }

        this.applicForm.controls['property_bank_name'].setValue(
          this.userDetailData[0].propertyInformation?.nameOfBankInPropertyInfo
        );
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('periodValidity')) {
          const myArray1 = this.userDetailData[0].propertyInformation.periodValidity.split('/');
          const periodValidityformatted = myArray1[2] + '-' + myArray1[1] + '-' + myArray1[0];
          this.applicForm.controls['period_validity'].setValue(periodValidityformatted);
        }
        this.applicForm.controls['property_postcode'].setValue(
          this.userDetailData[0].propertyInformation?.postCodeInPropertyInfo
        );
        this.applicForm.controls['property_address'].setValue(
          this.userDetailData[0].propertyInformation?.propertyAddress
        );
        this.applicForm.controls['property_encumbered'].setValue(
          this.userDetailData[0].propertyInformation?.propertyEncumbered
        );
        this.applicForm.controls['property_type'].setValue(this.userDetailData[0].propertyInformation?.propertyType);
        this.applicForm.controls['renewal_insurance_caga'].setValue(
          this.userDetailData[0].propertyInformation?.renewalOfFireAndHomeInsurancebyCaga
        );
        this.applicForm.controls['renewal_insurence'].setValue(
          this.userDetailData[0].propertyInformation?.renewalOfFireAndHomeInsurance
        );
        this.applicForm.controls['sale_purchase_price'].setValue(
          this.userDetailData[0].propertyInformation?.saleAndPurchasePrice
        );
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('sandPDate')) {
          const myArray2 = this.userDetailData[0].propertyInformation.sandPDate.split('/');
          const sandPDateformatted = myArray2[2] + '-' + myArray2[1] + '-' + myArray2[0];
          this.applicForm.controls['sale_purchase_date'].setValue(sandPDateformatted);
        }
        this.applicForm.controls['property_tenure'].setValue(
          this.userDetailData[0].propertyInformation?.tentureOfPropertyTitle
        );
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('valuationDate')) {
          const myArray3 = this.userDetailData[0].propertyInformation.valuationDate.split('/');
          const valuationDateformatted = myArray3[2] + '-' + myArray3[1] + '-' + myArray3[0];
          this.applicForm.controls['valuation_date'].setValue(valuationDateformatted);
        }
        this.applicationId = this.userDetailData[0].applicationId;

        this.applicForm.controls['property_bank_name'].setValue(
          this.userDetailData[0].propertyInformation?.nameOfBankInPropertyInfo
        );
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('periodValidity')) {
          const myArray1 = this.userDetailData[0].propertyInformation.periodValidity.split('/');
          const periodValidityformatted = myArray1[2] + '-' + myArray1[1] + '-' + myArray1[0];
          this.applicForm.controls['period_validity'].setValue(periodValidityformatted);
        }
        this.applicForm.controls['property_postcode'].setValue(
          this.userDetailData[0].propertyInformation?.postCodeInPropertyInfo
        );
        this.applicForm.controls['property_address'].setValue(
          this.userDetailData[0].propertyInformation?.propertyAddress
        );
        this.applicForm.controls['property_encumbered'].setValue(
          this.userDetailData[0].propertyInformation?.propertyEncumbered
        );
        this.applicForm.controls['property_type'].setValue(this.userDetailData[0].propertyInformation?.propertyType);
        this.applicForm.controls['renewal_insurance_caga'].setValue(
          this.userDetailData[0].propertyInformation?.renewalOfFireAndHomeInsurancebyCaga
        );
        this.applicForm.controls['renewal_insurence'].setValue(
          this.userDetailData[0].propertyInformation?.renewalOfFireAndHomeInsurance
        );
        this.applicForm.controls['sale_purchase_price'].setValue(
          this.userDetailData[0].propertyInformation?.saleAndPurchasePrice
        );
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('sandPDate')) {
          const myArray2 = this.userDetailData[0].propertyInformation.sandPDate.split('/');
          const sandPDateformatted = myArray2[2] + '-' + myArray2[1] + '-' + myArray2[0];
          this.applicForm.controls['sale_purchase_date'].setValue(sandPDateformatted);
        }
        this.applicForm.controls['property_tenure'].setValue(
          this.userDetailData[0].propertyInformation?.tentureOfPropertyTitle
        );
        if (this.userDetailData[0].propertyInformation.hasOwnProperty('valuationDate')) {
          const myArray3 = this.userDetailData[0].propertyInformation.valuationDate.split('/');
          const valuationDateformatted = myArray3[2] + '-' + myArray3[1] + '-' + myArray3[0];
          this.applicForm.controls['valuation_date'].setValue(valuationDateformatted);
        }
        this.applicationId = this.userDetailData[0].applicationId;
      }
    });
  };

  submitForm() {
    if (this.applicForm.valid) {
      const dateSendingToServer = new DatePipe('en-US').transform(this.applicForm.value.dob, 'dd/MM/yyyy');
      this.joint_part.orderProgress = 'FORM2_DETAILS';

      console.log('joint_part', this.joint_part);
      // this.applicationId= this.joint_part.applicationId;

      this.joint_part.jointApplicantDetails = {
        jointApplicantDateOfBirth: dateSendingToServer,
        jointApplicantEmail: this.applicForm.value.email,
        jointApplicantEmployerAddress: this.applicForm.value.address_of_employer,
        jointApplicantEmployerName: this.applicForm.value.name_of_employer,
        jointApplicantEmployerPostCode: this.applicForm.value.postcode_of_employer,
        jointApplicantHandPhoneNumber: this.applicForm.value.hp_tel,
        jointApplicantMaritalStatus: this.applicForm.value.marital_status,
        jointApplicantMonthlyIncome: 0,
        jointApplicantNameAsPerIc: this.applicForm.value.applicant_name,
        jointApplicantNationality: this.applicForm.value.nationality,
        jointApplicantNricNumber: this.applicForm.value.nric_no,
        jointApplicantOccupation: this.applicForm.value.occupation,
        jointApplicantPostCode: this.applicForm.value.postcode,
        jointApplicantRace: this.applicForm.value.race,
        jointApplicantRelationshipWithApplicant: this.applicForm.value.relationship,
        jointApplicantResPhoneNumber: this.applicForm.value.res_tel,
        jointApplicantResidentialAddress: this.applicForm.value.res_address,
        jointApplicantSalutation: this.applicForm.value.salutation,
        jointApplicantSex: this.applicForm.value.sex,
      };
      this.joint_part.bankDetails = {
        accountNumber: this.applicForm.value.account_number,
        accountType: this.applicForm.value.account_type,
        bankName: this.applicForm.value.name_of_bank,
        accountTypeConIsl: this.applicForm.value.account_type_con_isl,
      };
      this.joint_part.propertyInformation = {
        buildUpArea: this.applicForm.value.build_up_area,
        estimatedOutstandingBalance: this.applicForm.value.outstanding_balns,
        expectedMarketValue: this.applicForm.value.expectation_market_value,
        marketValue: this.applicForm.value.market_value,
        fireAndHomeInsurancePolicy: this.applicForm.value.fire_home_insurence,
        indicativeMarketValue: this.applicForm.value.market_value,
        insurerName: this.applicForm.value.insurer_name,
        landArea: this.applicForm.value.land_area,
        leaseExpiryDate: new DatePipe('en-US').transform(this.applicForm.value.lease_expiry_date, 'dd/MM/yyyy'),
        nameOfBankInPropertyInfo: this.applicForm.value.property_bank_name,
        periodValidity: new DatePipe('en-US').transform(this.applicForm.value.period_validity, 'dd/MM/yyyy'),
        postCodeInPropertyInfo: this.applicForm.value.property_postcode,
        propertyAddress: this.applicForm.value.property_address,
        propertyEncumbered: this.applicForm.value.property_encumbered,
        propertyType: this.applicForm.value.property_type,
        renewalOfFireAndHomeInsurancebyCaga: this.applicForm.value.renewal_insurance_caga,
        renewalOfFireAndHomeInsurance: this.applicForm.value.renewal_insurence,
        saleAndPurchasePrice: this.applicForm.value.sale_purchase_price,
        sandPDate: new DatePipe('en-US').transform(this.applicForm.value.sale_purchase_date, 'dd/MM/yyyy'),
        tentureOfPropertyTitle: this.applicForm.value.property_tenure,
        valuationDate: new DatePipe('en-US').transform(this.applicForm.value.valuation_date, 'dd/MM/yyyy'),
      };

      this.userservice.submit_akpk_one(this.joint_part).subscribe(
        (data) => {
          localStorage.setItem('part-two', JSON.stringify(data));
          this.router.navigate(['/application-part-three']);
        },
        (error) => {
          Swal.fire(this.tryagain, '', 'info');
        }
      );
    } else {
      console.log(this.application_part_one_data);
      this.markFormGroupTouched(this.applicForm);
    }
  }
  validateFloatKeyPress(event: any) {
    var v = parseFloat(event.value);
    event.value = isNaN(v) ? '' : v.toFixed(2);
  }

  check_date(e: any) {
    // const convertAge = new Date(e.target.value);
    // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    // var ages = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    var today: any = new Date();
    var birthDate = new Date(e.target.value);
    var ages = Math.floor((today - new Date(birthDate).getTime()) / 3.15576e10);
    console.log('ages>>', ages);
    if (ages < 55) {
      this.ageerror = true;
    } else if (ages > 55) {
      this.ageerror = false;
    } else if (ages == 55) {
      this.ageerror = false;
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
  prevStep = () => {
    this.router.navigate(['/application']);
  };
}
