<!-- Application Form -->
<section class="application main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <h2 class="heading mt-3">SKIM SARAAN BERCAGAR APPLICATION FORM</h2>
          </div>
          <div class="col-md-3 d-flex justify-content-end align-items-center">
            <div class="application-logo-text">
              <h3>Strictly Confidential</h3>
              <h5>OPE/SSB/FRM/001/v8</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="application_form col-12">
        <form (ngSubmit)="apkpk_one()" [formGroup]="applicForm" novalidate>
          <div class="application_joint mb-4">
            <p class="mb-2">JOINT APPLICANT (Y/N)</p>

            <div class="form-group">
              <div class="form-check form-check-inline">
                <input type="radio" value="yes" formControlName="joint" class="form-check-input" id="yes" />
                <label for="yes">Yes </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" value="no" formControlName="joint" class="form-check-input" id="no" />
                <label for="no">No</label>
              </div>
              <div
                [hidden]="applicForm.controls['joint'].valid || applicForm.controls['joint'].untouched"
                class="text-danger px-2 mx-3"
                translate
              >
                {{ 'COMMON.FIELDREQUIRED' | translate }}
              </div>
            </div>
          </div>

          <div class="form-blue-bg mb-5">
            <div class="row">
              <div class="col-md-12"><h4 class="para-title">PARTICULAR OF APPLICANT</h4></div>
              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="" class="control-label">Salutation : </label>
                  <select id="salutation " class="form-control">
                    <option value="Mr">Mr</option>
                    <option value="Mdm">Mdm</option>
                    <option value="Ms">Ms</option>
                    <option value="Tan Sri">Tan Sri</option>
                    <option value="Dato’">Dato’</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div class="form-group required mb-4">
                  <label for="name" class="control-label">Name as per NRIC : </label>
                  <input type="text" class="form-control" formControlName="applicant_name" id="name" />
                  <div
                    [hidden]="
                      applicForm.controls['applicant_name'].valid || applicForm.controls['applicant_name'].untouched
                    "
                    class="text-danger"
                    translate
                  >
                    Name is required
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="nric" class="control-label">NRIC No. : </label>
                  <input type="text" class="form-control" formControlName="nric_no" id="nric" />
                  <div
                    *ngIf="applicForm.controls['nric_no'].errors?.['required'] && applicForm.controls['nric_no'].touched"
                    class="text-danger"
                    translate
                  >
                    NRIC No is required
                  </div>
                  <div *ngIf="applicForm.controls['nric_no'].errors?.['minlength']" class="text-danger">
                    NRIC No should be at least 12 characters
                  </div>
                  <div *ngIf="applicForm.controls['nric_no'].errors?.['maxlength']" class="text-danger">
                    NRIC No should be 12 characters
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="address" class="control-label">Residential Address : </label>
                  <input type="text" class="form-control" formControlName="res_address" id="address" />
                  <div
                    *ngIf="applicForm.controls['res_address'].errors?.['required'] && applicForm.controls['res_address'].touched"
                    class="text-danger"
                    translate
                  >
                    Residential Address is required
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="post" class="control-label">Postcode : </label>
                  <input
                    class="form-control"
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    onpaste="return false"
                    formControlName="postcode"
                    id="post"
                  />
                  <div
                    *ngIf="applicForm.controls['postcode'].errors?.['required'] && applicForm.controls['postcode'].touched"
                    class="text-danger"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD11ERROR' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['postcode'].errors?.['minlength']" class="text-danger">
                    {{ 'APPLICATION.FORM1.FIELD11ERROR2' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['postcode'].errors?.['maxlength']" class="text-danger">
                    {{ 'APPLICATION.FORM1.FIELD11ERROR3' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="email" class="control-label"> {{ 'REGISTRATION.FORM.FIELD2' | translate }} : </label>
                  <input type="email" class="form-control" formControlName="email" id="email" />
                  <div
                    *ngIf="applicForm.controls['email'].errors?.['required'] && !applicForm.controls['email'].untouched"
                    class="text-danger"
                  >
                    {{ 'REGISTRATION.FORM.FIELD2ERROR1' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['email'].errors?.['pattern']" class="text-danger">
                    {{ 'REGISTRATION.FORM.FIELD2ERROR1' | translate }}
                  </div>
                </div>

                <div class="form-group mb-4">
                  <label for="tel" class="control-label">Telephone No. (Residence) : </label>
                  <input type="number" class="form-control" formControlName="res_tel" id="tel" />
                </div>

                <div class="form-group mb-4">
                  <label for="telNo" class="control-label">Telephone No (H/P) : </label>
                  <input
                    class="form-control"
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                    onpaste="return false"
                    formControlName="hp_tel"
                    id="telNo"
                  />
                  <!-- <div
                    *ngIf="applicForm.controls['hp_tel'].errors?.['required'] && applicForm.controls['hp_tel'].touched"
                    class="text-danger"
                  >
                    Telephone No (H/P) is required
                  </div> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="dob_box form-group required mb-4">
                  <label for="birthday">Date of Birth :</label>
                  <input
                    type="date"
                    class="form-control"
                    id="dob"
                    max="9999-12-31"
                    formControlName="dob"
                    (change)="check_date($event)"
                  />

                  <div
                    *ngIf="applicForm.controls['dob'].errors?.['required'] && applicForm.controls['dob'].touched"
                    class="text-danger"
                  >
                    Date of Birth is required
                  </div>
                  <div *ngIf="ageerror" class="text-danger">Age must be 55 and above</div>
                </div>

                <div class="form-group required mb-4">
                  <label for="Race" class="control-label">Race : </label>
                  <select formControlName="race" id="Race" class="form-control">
                    <option value="Malay">Malay</option>
                    <option value="Chinese">Chinese</option>
                    <option value="Indian">Indian</option>
                    <option value="Others">Others - Bumiputra</option>
                    <option value="Others">Others - Non Bumiputra</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['race'].errors?.['required'] && applicForm.controls['race'].touched"
                    class="text-danger"
                  >
                    Race is required
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="nationality" class="control-label">Nationality : </label>
                  <select formControlName="nationality" id="nationality" class="form-control">
                    <option value="Malaysian">Malaysian</option>
                    <option value="Non-Malaysian">Non-Malaysian</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['nationality'].errors?.['required'] && !applicForm.controls['nationality'].untouched"
                    class="text-danger"
                    translate
                  >
                    {{ 'COMMON.NATIONALITYREQUIRED' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['nationality'].errors?.['NationalityValidators']" class="text-danger">
                    Only Malaysian can apply!
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="" class="d-block control-label">Sex : </label>
                  <div class="form-check form-check-inline">
                    <input type="radio" id="male" formControlName="sex" class="form-check-input" value="Male" />
                    <label for="male" class="mt-1 mx-2">Male </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" id="female" formControlName="sex" class="form-check-input" value="Female" />
                    <label for="female" class="mt-1 mx-2">Female</label>
                  </div>
                  <div
                    *ngIf="applicForm.controls['sex'].errors?.['required'] && !applicForm.controls['sex'].untouched"
                    class="text-danger"
                    translate
                  >
                    SEX is required
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="marital" class="control-label">Marital Status : </label>
                  <select formControlName="marital_status" id="marital" class="form-control">
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Indian">Widowed</option>
                    <option value="Others">Divorced</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['marital_status'].errors?.['required'] && !applicForm.controls['marital_status'].untouched"
                    class="text-danger"
                    translate
                  >
                    Marital Status is required
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="no" class="control-label">No of Dependent(s) : </label>
                  <input type="number" class="form-control" formControlName="no_dep" id="no" />
                </div>

                <div class="form-group mb-4 form-line5">
                  <label for="name">Age(s) : </label>
                  <div class="form-line5-inn">
                    <input type="number" class="form-control" formControlName="no_dep1" id="no5" />
                    <input type="number" class="form-control" formControlName="no_dep2" id="no1" />
                    <input type="number" class="form-control" formControlName="no_dep3" id="no2" />
                    <input type="number" class="form-control" formControlName="no_dep4" id="no3" />
                    <input type="number" class="form-control" formControlName="no_dep5" id="no4" />
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="phouse" class="control-label">Present House : </label>
                  <select formControlName="present_house" id="phouse" class="form-control">
                    <option value="Own">Own</option>
                    <option value="Rented">Rented</option>
                    <option value="Mortgaged">Mortgaged</option>
                    <option value="Family">Family</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['present_house'].errors?.['required'] && !applicForm.controls['present_house'].untouched"
                    class="text-danger"
                    translate
                  >
                    Present House is required
                  </div>
                </div>
              </div>

              <div class="col-md-12 my-4">
                <hr />
              </div>

              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="occupation" class="control-label">Occupation : </label>
                  <input type="text" class="form-control" formControlName="occupation" id="occupation" />
                  <div
                    *ngIf="applicForm.controls['occupation'].errors?.['required'] && !applicForm.controls['occupation'].untouched"
                    class="text-danger"
                    translate
                  >
                    Occupation is required
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-12">
                <p>*Previous occupation, if retired/ unemployed</p>
              </div>

              <div class="col-md-4">
                <div class="form-group required mb-4">
                  <label for="emp" class="control-label">Name of Employer : </label>
                  <input type="text" class="form-control" formControlName="name_of_employer" id="emp" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label for="address" class="control-label">Address of Employer : </label>
                  <input type="text" class="form-control" formControlName="address_of_employer" id="address" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label for="post">Postcode : </label>
                  <input type="number" class="form-control" formControlName="postcode_of_employer" id="post" />
                </div>
              </div>

              <div class="col-md-12 my-4">
                <hr />
              </div>

              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="purpose_of_ssb" class="control-label">Purpose of SSB application : </label>
                  <select formControlName="purpose_of_ssb" class="form-control" id="purpose_of_ssb">
                    <option value="Own">Own</option>
                    <option value="Rented">Rented</option>
                    <option value="Mortgaged">Mortgaged</option>
                    <option value="Family">Family</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['purpose_of_ssb'].errors?.['required'] && !applicForm.controls['purpose_of_ssb'].untouched"
                    class="text-danger"
                    translate
                  >
                    Purpose of SSB application is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="" class="d-block control-label">SSB Payout Option (Choose one) : </label>

                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="monthly"
                      class="form-check-input"
                      formControlName="payout_option"
                      value="monthly"
                    />
                    <label for="monthly" class="mt-1 mx-2">Monthly Payout only Male </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lumpsum"
                      class="form-check-input"
                      formControlName="payout_option"
                      value="lumpsum"
                    />
                    <label for="lumpsum" class="mt-1 mx-2">Lumpsum & Monthly Payout</label>
                  </div>

                  <div
                    *ngIf="applicForm.controls['payout_option'].errors?.['required'] && !applicForm.controls['payout_option'].untouched"
                    class="text-danger"
                    translate
                  >
                    SSB Payout Option is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group required mb-4">
                  <div class="row">
                    <div class="col-md-4">
                      <label for="" class="control-label">Usage of Lum Sum : </label>
                    </div>

                    <div class="col-md-8">
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="medical"
                          class="form-check-input"
                          formControlName="usage_of_lumpsum"
                          value="medical_expenses"
                        />
                        <label class="mt-1 mx-2" for="medical">Payment for medical expenses</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="mortgage"
                          class="form-check-input"
                          formControlName="usage_of_lumpsum"
                          value="mortage_loan"
                        />
                        <label class="mt-1 mx-2" for="mortgage"
                          >Settle any outstanding mortgage loan on the property to be charged under the SSB Loan
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="maintenance"
                          class="form-check-input"
                          formControlName="usage_of_lumpsum"
                          value="refurbish_maintenance"
                        />
                        <label class="mt-1 mx-2" for="maintenance"
                          >Refurbishment and maintenance expenses to upkeep the property </label
                        ><br />
                      </div>
                      <div>
                        <small id="passwordHelpBlock" class="form-text text-muted">
                          * If Lump Sum option is chosen, supporting documents will be needed. Please refer to the list
                          of documents to be enclosed at the end of this application form.
                        </small>
                      </div>
                    </div>
                    <div
                      *ngIf="applicForm.controls['usage_of_lumpsum'].errors?.['required'] && !applicForm.controls['usage_of_lumpsum'].untouched"
                      class="text-danger"
                      translate
                    >
                      Usage of Lum Sum is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group required mb-4">
                  <div class="row">
                    <div class="col-md-4">
                      <label for="" class="control-label">Payment of Initial Costs & Expenses : </label>
                    </div>

                    <div class="col-md-8">
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="applicant"
                          class="form-check-input"
                          formControlName="cost_expenses"
                          value="applicant"
                        />
                        <label class="mt-1 mx-2" for="applicant">To be paid by applicant/borrower</label><br />
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="cagamas"
                          class="form-check-input"
                          formControlName="cost_expenses"
                          value="cagamas"
                        />
                        <label class="mt-1 mx-2" for="cagamas"
                          >To be advanced by Cagamas and added to the SSB loan amount</label
                        ><br />
                      </div>
                    </div>
                    <div
                      *ngIf="applicForm.controls['cost_expenses'].errors?.['required'] && !applicForm.controls['cost_expenses'].untouched"
                      class="text-danger"
                      translate
                    >
                      Payment of Initial Costs & Expenses is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="m-3 text-center">
                <button class="btn btn-primary btn-custom" type="submit" [disabled]="isLoading">
                  <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                  <span translate>Next</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
