import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationPartTwoRoutingModule } from './application-part-two-routing.module';
import { ApplicationPartTwoComponent } from './application-part-two.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ApplicationPartTwoComponent],
  imports: [CommonModule, TranslateModule, ApplicationPartTwoRoutingModule, ReactiveFormsModule],
})
export class ApplicationPartTwoModule {}
