import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';

import Swal from 'sweetalert2';
import { AuthenticationService } from '../authentication.service';
import { browserRefresh } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-forgot-email',
  templateUrl: './forgot-email.component.html',
  styleUrls: ['./forgot-email.component.scss'],
})
export class ForgotEmailComponent implements OnInit {
  error: string | undefined;
  forgotpassForm!: FormGroup;
  isLoading = false;
  _requestFrom: string = '';
  succmess: string = '';
  succhead: string = '';
  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.translate.get('FORGOT').subscribe((data: any) => {
      this.succmess = data.FORM.SUCCMESS2;
      this.succhead = data.FORM.SUCCHEAD;
    });
    this.route.data.subscribe((data) => {
      this._requestFrom = data['requestFrom'];
    });
    this._onSubmit();
    console.log('FOR', this._requestFrom);
    if (browserRefresh == true) {
      this.router.navigate(['/']);
    }
  }
  _redirectLogin = () => {
    if (this._requestFrom == 'ROLE_CUSTOMER') {
      this.router.navigate(['/login']);
    }
    if (this._requestFrom == 'ROLE_CAGAMAS_USER') {
      this.router.navigate(['/cagamas-user/login']);
    }
    if (this._requestFrom == 'ROLE_IT_ADMIN') {
      this.router.navigate(['/admin/login']);
    }
  };

  _onSubmit = () => {
    if (this._requestFrom == 'ROLE_CUSTOMER') {
      this.forgot_pass();
    }
    if (this._requestFrom == 'ROLE_CAGAMAS_USER') {
      this.cagmasForgotPassword();
    }
    if (this._requestFrom == 'ROLE_IT_ADMIN') {
      this.cagmasForgotPassword();
    }
  };
  cagmasForgotPassword = () => {
    //alert('Cagmas Forgot Password');
  };
  private createForm() {
    this.forgotpassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }
  forgot_pass() {
    console.log('test');
    this.isLoading = true;
    if (this.forgotpassForm.valid) {
      var body = {
        email: this.forgotpassForm.value.email,
        url: environment.hostUrl + '/forgot-password-reset/' + this.forgotpassForm.value.email,
      };
      localStorage.setItem('url', environment.hostUrl + '/forgot-password-reset/' + this.forgotpassForm.value.email);
      this.authenticationService
        .forgot_pass_email(body)
        .pipe(
          finalize(() => {
            this.forgotpassForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (credentials) => {
            if (credentials == 'success' || credentials == 'OK') {
              Swal.fire({
                icon: 'success',
                title: this.succhead,
                text: this.succmess,
              });
            } else {
              Swal.fire({ icon: 'error', title: 'Oops...', text: credentials.message });
            }
          },
          (error) => {
            if (error.statusText == 'success' || error.statusText == 'OK') {
              Swal.fire({
                icon: 'success',
                title: this.succhead,
                text: this.succmess,
              });
            } else {
              Swal.fire({ icon: 'error', title: 'Oops...', text: error.error.message });
            }
          }
        );
    } else {
      this.markFormGroupTouched(this.forgotpassForm);
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
}
