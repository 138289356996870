import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationPartTwoComponent } from '@app/application-part-two/application-part-two.component';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ApplicationPartThreeComponent } from './application-part-three.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'application-part-three',
      component: ApplicationPartThreeComponent,
      data: { title: marker('Submit Application') },
    },
  ]),
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApplicationPartThreeRoutingModule {}
