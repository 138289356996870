import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserListRoutingModule } from './user-list-routing.module';
import { UserListComponent } from './user-list.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [UserListComponent],
  imports: [
    CommonModule,
    UserListRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NgxPaginationModule,
  ],
})
export class UserListModule {}
