import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { submittedpageComponent } from './submittedpage.component';

const routes: Routes = [
  Shell.childRoutes([{ path: 'submitted', component: submittedpageComponent, data: { title: marker('Submitted') } }]),

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubmittedPageRoutingModule {}
