import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { UserService } from '@app/auth/user.service';
import { CustomValidators } from '../../../providers/CustomValidators';
import Swal from 'sweetalert2';
import { EligibilityCheckService } from '../../auth/eligibility--check.service';
import { empty, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ThisReceiver } from '@angular/compiler';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  closeResult: string = '';
  error: string | undefined;
  resetnForm!: FormGroup;
  isLoading = false;
  eligible = true;
  isOldPassbord = false;
  akpktext: any;
  button1: string = '';
  button2: string = '';
  resetmessage: string = '';
  resetbutton: string = '';
  resetheader: string = '';
  url: string = '';
  SESSIONERROR: string = '';
  subscription!: Subscription;
  constructor(
    public translate: TranslateService,
    private userservice: UserService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private EligibilityCheck: EligibilityCheckService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.subscription = interval(60000).subscribe((x) => {
      this.validatetoken();
    });
    if (localStorage.getItem('language') != null && localStorage.getItem('language') != '') {
      const lang = localStorage.getItem('language');
      console.log('lang' + lang);
      this.translate.setDefaultLang(lang ? lang : '');
      this.translate.use(lang ? lang : '');
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    //this.validatetoken();
    this.usersubmittedapplication();

    this.translate.get('AKPK').subscribe((data: any) => {
      this.akpktext = data.HTML;
      this.button1 = data.BUTTON1;
      this.button2 = data.BUTTON2;
      this.resetmessage = data.RESETMESSAGE;
      this.resetbutton = data.RESETBUTTON;
      this.resetheader = data.RESETHEADER;
    });
    this.translate.get('COMMON').subscribe((data: any) => {
      this.SESSIONERROR = data.SESSIONERROR;
    });
    this.url = this.router.url;
  }

  validatetoken() {
    setTimeout(() => {
      const token = this.credentialsService.credentials?.token;
      if (token != null) {
        this.userservice.validate().subscribe(
          (res) => {
            console.log(res);
          },
          (error) => {
            if (error.statusText != 'OK') {
              Swal.fire({
                title: 'Oops...',
                text: this.SESSIONERROR,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
              }).then((result: any) => {
                if (result.isConfirmed) {
                  this.credentialsService.setCredentials();
                  localStorage.removeItem('bearer');
                  localStorage.removeItem('credentials');
                  window.open('/main-page', '_self');
                  // this.router.navigate(['/main-page']);
                }
              });
            }
          }
        );
      }
    }, 5000);
  }
  switch(language: any) {
    /** For Malashiyan */
    localStorage.setItem('language', language);
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.translate.get('AKPK').subscribe((data: any) => {
      this.akpktext = data.HTML;
      this.button1 = data.BUTTON1;
      this.button2 = data.BUTTON2;
      this.resetmessage = data.RESETMESSAGE;
      this.resetbutton = data.RESETBUTTON;
      this.resetheader = data.RESETHEADER;
    });
    location.reload();
  }
  private createForm() {
    this.resetnForm = this.formBuilder.group(
      {
        old_password: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: CustomValidators('password', 'confirmPassword'),
      }
    );
  }
  _defaultPass: boolean = false;
  _secondryPass: boolean = false;
  _oldPass: boolean = false;
  togglepassword = (_requestFrom: string) => {
    if (_requestFrom == 'P') {
      this._defaultPass = !this._defaultPass;
    }
    if (_requestFrom == 'S') {
      this._secondryPass = !this._secondryPass;
    }
    if (_requestFrom == 'O') {
      this._oldPass = !this._oldPass;
    }
  };
  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.resetnForm.markAsPristine();
        this.resetnForm.reset();
      },
      (reason) => {
        this.closeResult = `Dismissed `;
      }
    );
  }
  logout() {
    this.credentialsService.setCredentials();
    localStorage.removeItem('bearer');
    this.credentialsService.setCredentials();
    localStorage.removeItem('credentials');
    //localStorage.clear();
    window.open('/login', '_self');
    //this.router.navigate(['/login']);

    // this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }
  get email(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.email : null;
  }

  reset = () => {
    if (this.resetnForm.controls['password'].value === this.resetnForm.controls['old_password'].value) {
      this.isOldPassbord = true;
    } else {
      var body = {
        confirmPassword: this.resetnForm.value.confirmPassword,
        email: this.email,
        newPassword: this.resetnForm.value.password,
        oldPassword: this.resetnForm.value.old_password,
      };
      this.authenticationService.resetPassword(body).subscribe(
        (data) => {
          if (!data.hasOwnProperty('error')) {
            Swal.fire({
              title: this.resetheader,
              text: this.resetmessage,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: this.resetbutton,
            }).then((result) => {
              if (result.isConfirmed) {
                this.credentialsService.setCredentials();
                this.router.navigate(['/login']);
              }
            });
            // Swal.fire({ icon: 'success', title: 'Success...', text: 'Password Reset Successfully. Please login ' });
            // //this.router.navigate(['/login']);
            // this.logout;
          } else {
            Swal.fire({ icon: 'error', title: 'Oops...', text: data.message });
          }
        },
        (error) => {
          Swal.fire({ icon: 'error', title: 'Oops...', text: error.error.message });
        }
      );
      this.modalService.dismissAll();
    }
  };
  usersubmittedapplication() {
    this.userservice.getapplication().subscribe((responce) => {
      var data = responce.find(function (ele: any) {
        return ele.orderStatus === 'SUBMITTED';
      });
      if (data) {
        this.eligible = true;
      } else {
        this.eligible = false;
      }
    });
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }

  getAKPK() {
    //window.open(environment.apiUrl + 'api/download-attachment/akpk.docx', '');
    // if (localStorage.getItem('language') == 'en') {
    //   this.userservice.getakpkdocument();
    // } else {
    //   this.userservice.getakpkdocumentmy();
    // }

    Swal.fire({
      html: this.akpktext,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: this.button1,
      cancelButtonText: this.button2,
      cancelButtonColor: '#0f5298',
      confirmButtonColor: '#0f5298',
      denyButtonColor: '#0f5298',
      // allowOutsideClick: false,
      showCloseButton: false,
      denyButtonText: this.button2,

      // icon: 'success',
    }).then((result) => {
      if (result.isConfirmed) {
        // window.open('https://mybijakkewangan.akpk.org.my/', '_blank');
        if (localStorage.getItem('language') == 'en') {
          this.userservice.getakpkdocument();
        } else {
          this.userservice.getakpkdocumentmy();
        }
      } else if (result.isDismissed) {
        // Swal.fire('Changes are not saved', '', 'info');
        this.router.navigate(['/dashboard']);
      } else if (result.isDenied) {
        window.open('https://mybijakkewangan.akpk.org.my/', '_blank');
      }
    });
  }
}
