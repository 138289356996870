import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { ApplicationPartOneComponent } from './application-part-one.component';
import { ApplicationPartOneRoutingModule } from './application-part-one-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, ReactiveFormsModule, ApplicationPartOneRoutingModule],
  declarations: [ApplicationPartOneComponent],
})
export class ApplicationPartOneModule {}
