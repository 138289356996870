import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserService } from '@app/auth/user.service';
import { CredentialsService } from '@app/auth';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { empty, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-submitted-page',
  templateUrl: './submittedpage.component.html',
  styleUrls: ['./submittedpage.component.scss'],
})
export class submittedpageComponent implements OnInit {
  subscription!: Subscription;
  akpktext: any;
  eligible = true;
  button1: string = '';
  button2: string = '';
  resetmessage: string = '';
  resetbutton: string = '';
  resetheader: string = '';
  SESSIONERROR: string = '';
  url: string = '';
  constructor(
    public translate: TranslateService,
    private userservice: UserService,
    private router: Router,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.subscription = interval(60000).subscribe((x) => {
      this.validatetoken();
    });
    if (localStorage.getItem('language') != null && localStorage.getItem('language') != '') {
      const lang = localStorage.getItem('language');
      console.log('lang' + lang);
      this.translate.setDefaultLang(lang ? lang : '');
      this.translate.use(lang ? lang : '');
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

  
    this.url = this.router.url;
  }

  validatetoken() {
    setTimeout(() => {
      const token = this.credentialsService.credentials?.token;
      if (token != null) {
        this.userservice.validate().subscribe(
          (res) => {
            console.log(res);
          },
          (error) => {
            if (error.statusText != 'OK') {
              Swal.fire({
                title: 'Oops...',
                text: this.SESSIONERROR,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
              }).then((result: any) => {
                if (result.isConfirmed) {
                  this.credentialsService.setCredentials();
                  localStorage.removeItem('bearer');
                  localStorage.removeItem('credentials');
                  window.open('/main-page', '_self');
                  // this.router.navigate(['/main-page']);
                }
              });
            }
          }
        );
      }
    }, 5000);
  }

  switch(language: any) {
    /** For Malashiyan */
    localStorage.setItem('language', language);
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.translate.get('AKPK').subscribe((data: any) => {
      this.akpktext = data.HTML;
      this.button1 = data.BUTTON1;
      this.button2 = data.BUTTON2;
      this.resetmessage = data.RESETMESSAGE;
      this.resetbutton = data.RESETBUTTON;
      this.resetheader = data.RESETHEADER;
    });
    location.reload();
  }


  submitForm(){
    window.open('/dashboard', '_self');


  }
 

  
}
