<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/css/all.min.css"
  integrity="sha512-3M00D/rn8n+2ZVXBO9Hib0GKNpkm8MSUU/e2VNthDyBYxKWG+BftNYYcuEjXlyrSO637tidzMBXfE7sQm0INUg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>
<div class="login-container bg-light">
  <div class="login-box">
    <div class="container-fluid h-100">
      <div class="row h-100 mobile-min-height">
        <div class="col-md-6 bg-white p-2 p-lg-5 h-100">
          <div class="card border-0 width-400">
            <div class="card-body">
              <h4 class="card-title text-center m-0 m-md-3">
                <!--<h1 translate>APP_NAME</h1> -->
                <!--begin: Aside header-->
                <a href="#" class="flex-column-auto logo-hld">
                  <img src="../../assets/images/ssb-logo.svg" width="180" class="max-h-70px" alt="cagamas" />
                </a>
                <!--end: Aside header-->
                <h2 class="font-size-h1">{{ 'REGISTRATION.FORM.TITLE' | translate }}</h2>
                <p class="text-muted font-weight-bold">{{ 'REGISTRATION.FORM.DESCRIPTION' | translate }}</p>
              </h4>
              <form (ngSubmit)="registration()" class="mt-3" [formGroup]="registrationForm" novalidate>
                <div class="text-danger" [hidden]="!error || isLoading" translate>Email or password incorrect.</div>
                <div class="m-0 m-md-3 mt-md-0">
                  <label class="d-block my-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="fullname"
                      autocomplete="fullname"
                      [placeholder]="'REGISTRATION.FORM.FIELD1' | translate"
                      onkeypress="return (event.charCode > 64 &&
        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode > 31 && event.charCode < 33)"
                    />
                    <span hidden translate>{{ 'REGISTRATION.FORM.FIELD1' | translate }}</span>
                    <small
                      [hidden]="
                        registrationForm.controls['fullname'].valid || registrationForm.controls['fullname'].untouched
                      "
                      class="text-danger"
                      translate
                    >
                      {{ 'REGISTRATION.FORM.FIELD1ERROR' | translate }}
                    </small>
                  </label>
                  <label class="d-block my-3">
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      autocomplete="email"
                      [placeholder]="'REGISTRATION.FORM.FIELD2' | translate"
                    />
                    <span hidden translate>{{ 'REGISTRATION.FORM.FIELD2' | translate }}</span>

                    <small
                      *ngIf="registrationForm.controls['email'].errors?.['required'] && !registrationForm.controls['email'].untouched"
                      class="text-danger"
                      >{{ 'REGISTRATION.FORM.FIELD2ERROR1' | translate }}</small
                    >
                    <small *ngIf="registrationForm.controls['email'].errors?.['pattern']" class="text-danger">{{
                      'REGISTRATION.FORM.FIELD2ERROR2' | translate
                    }}</small>
                  </label>
                  <label class="d-block mb-3">
                    <input
                      type="text"
                      class="form-control"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                      formControlName="phoneNo"
                      autocomplete="nopedfgsdfg"
                      [placeholder]="'REGISTRATION.FORM.FIELD3' | translate"
                      required
                    />
                    <span hidden translate>{{ 'REGISTRATION.FORM.FIELD3' | translate }}</span>
                    <small
                      *ngIf="registrationForm.controls['phoneNo'].errors?.['required'] && !registrationForm.controls['phoneNo'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'REGISTRATION.FORM.FIELD3ERROR1' | translate }}
                    </small>
                    <div *ngIf="registrationForm.controls['phoneNo'].errors?.['minlength']" class="text-danger">
                      {{ 'REGISTRATION.FORM.FIELD3ERROR2' | translate }}
                    </div>
                    <div *ngIf="registrationForm.controls['phoneNo'].errors?.['maxlength']" class="text-danger">
                      {{ 'REGISTRATION.FORM.FIELD3ERROR3' | translate }}
                    </div>
                  </label>

                  <label class="d-block mb-3">
                    <div class="position-relative">
                      <input
                        type="{{ !_defaultPass ? 'password' : 'text' }}"
                        class="form-control"
                        formControlName="password"
                        autocomplete="nopefds"
                        [placeholder]="'REGISTRATION.FORM.FIELD4' | translate"
                        required
                      />
                      <a (click)="togglepassword('P')" class="password-btn"
                        ><i *ngIf="!_defaultPass" class="far fa-eye-slash"></i>
                        <i *ngIf="_defaultPass" class="fas fa-eye"></i
                      ></a>
                      <!--<i class="fas fa-eye"></i>-->
                    </div>
                    <span hidden translate>{{ 'REGISTRATION.FORM.FIELD4' | translate }}</span>
                    <small
                      *ngIf="registrationForm.controls['password'].errors?.['required'] && !registrationForm.controls['password'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'REGISTRATION.FORM.FIELD4ERROR1' | translate }}
                    </small>
                    <small *ngIf="registrationForm.controls['password'].errors?.['minlength']" class="text-danger">{{
                      'REGISTRATION.FORM.FIELD4ERROR3' | translate
                    }}</small>
                    <small
                      *ngIf="registrationForm.controls['password'].errors?.['pattern'] && !registrationForm.controls['password'].errors?.['minlength']"
                      class="text-danger"
                      >{{ 'REGISTRATION.FORM.FIELD4ERROR2' | translate }}</small
                    >
                  </label>

                  <label class="d-block mb-3">
                    <div class="position-relative">
                      <input
                        type="{{ !_secondryPass ? 'password' : 'text' }}"
                        class="form-control"
                        formControlName="confirmPassword"
                        autocomplete="current-password"
                        [placeholder]="'REGISTRATION.FORM.FIELD5' | translate"
                        required
                      />
                      <a (click)="togglepassword('S')" class="password-btn"
                        ><i *ngIf="!_secondryPass" class="far fa-eye-slash"></i>
                        <i *ngIf="_secondryPass" class="fas fa-eye"></i
                      ></a>
                      <!--<i class="fas fa-eye"></i>-->
                    </div>
                    <span hidden translate>{{ 'REGISTRATION.FORM.FIELD5' | translate }}</span>
                    <small
                      *ngIf="registrationForm.controls['confirmPassword'].errors?.['required'] && !registrationForm.controls['confirmPassword'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'REGISTRATION.FORM.FIELD5ERROR1' | translate }}
                    </small>
                    <small
                      *ngIf="registrationForm.controls['confirmPassword'].errors?.['CustomValidators']"
                      class="text-danger"
                      >{{ 'REGISTRATION.FORM.FIELD5ERROR2' | translate }}</small
                    >
                  </label>
                </div>
                <div class="m-2 btn-login-hld">
                  <div class="my-2 mr-2 mt-3 mt-mt-0 order-md-1 order-2">
                    <span class="text-muted mr-2">{{ 'REGISTRATION.FORM.NOTE' | translate }}?</span>
                    <a href="/login" class="font-weight-bold">{{ 'REGISTRATION.BUTTON1' | translate }}</a>
                  </div>
                  <button
                    class="order-md-2 order-1 btn btn-primary btn-custom"
                    type="submit"
                    [disabled]="registrationForm.invalid || isLoading"
                  >
                    <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                    <span translate>{{ 'REGISTRATION.BUTTON2' | translate }} </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 right-login-bg">
          <div class="d-flex h-100 flex-row-fluid flex-column justify-content-between px-3 py-3 right-login-inn">
            <!--begin: Aside content-->
            <div class="flex-column-fluid d-flex flex-column justify-content-center h-100">
              <h3 class="login-r-heading mb-md-5 mb-3 text-white">{{ 'FORGOT.BANNER.TITLE' | translate }}</h3>
              <p class="login-r-para text-white opacity-80">
                {{ 'FORGOT.BANNER.SUBTITLE' | translate }}
              </p>
            </div>
            <!--end: Aside content-->
            <!--begin: Aside footer for desktop-->
            <div class="mt-10">
              <div class="footer-txt text-white text-right">{{ 'COMMON.COPYRIGHT' | translate }}</div>
            </div>
            <!--end: Aside footer for desktop-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
