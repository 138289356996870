<!-- Application Form -->
<section class="application">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="application_logo">
          <img src="assets/images/ssb-logo.svg" class="img-fluid" alt="" />
          <div class="application-logo-text">
            <h3>Strictly Confidential</h3>
            <h5>OPE/SSB/FRM/001/v8</h5>
          </div>
        </div>
      </div>

      <div class="application_form col-12">
        <form>
          <h4>PARTICULAR OF APPLICANT</h4>

          <div class="row m-auto">
            <div class="col-md-3 col-12 input_box">
              <label for="">Salutation : </label>
              <select id="salutation ">
                <option value="Mr ">Mr</option>
                <option value=" Mdm">Mdm</option>
                <option value="Ms">Ms</option>
                <option value="Tan Sri">Tan Sri</option>
                <option value="Dato’">Dato’</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="col-md-9 col-12 input_box">
              <div class="application_name">
                <label for="name">Name as per NRIC : </label>
                <input type="text" id="name" />
              </div>
            </div>

            <div class="col-12 input_box">
              <div class="application_full_box">
                <label for="name">NRIC No. : </label>
                <input type="text" id="nric" />
              </div>
            </div>
            <div class="col-12 input_box">
              <div class="application_full_box">
                <label for="name">Residential Address : </label>
                <input type="text" id="address" />
              </div>
            </div>
            <div class="col-6 input_box">
              <div class="application_full_box">
                <label for="name">Postcode : </label>
                <input type="number" id="post" />
              </div>
            </div>

            <div class="col-12 input_box">
              <div class="application_full_box">
                <label for="name">Email : </label>
                <input type="email" id="email" />
              </div>
            </div>
            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Telephone No. (Residence) : </label>
                <input type="number" id="tel" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Telephone No (H/P) : </label>
                <input type="number" id="telNo" />
              </div>
            </div>

            <div class="col-12 input_box">
              <div class="dob_box">
                <label for="birthday">Date of Birth :</label>
                <input type="date" id="dob" max="9999-12-31" />
                <label class="px-3">(DD/MM/YYYY)</label>
              </div>
            </div>

            <div class="col-md-3 col-12 input_box">
              <label for="">Race : </label>
              <select id="Race">
                <option value="Malay ">Malay</option>
                <option value="Chinese">Chinese</option>
                <option value="Indian">Indian</option>
                <option value="Others">Others - Bumiputra</option>
                <option value="Others">Others - Non Bumiputra</option>
              </select>
            </div>

            <div class="col-md-4 col-12 input_box">
              <label for="">Nationality : </label>
              <select id="Race">
                <option value="Malay ">Malaysian</option>
                <option value="Chinese">Non-Malaysian</option>
              </select>
            </div>

            <div class="col-md-5 col-12 input_box">
              <label for="">Sex : </label>
              <input type="radio" id="male" name="sex_value" value="Male" />
              <label for="male">Male </label>
              <input type="radio" id="female" name="sex_value" value="Female" />
              <label for="female">Female</label>
            </div>

            <div class="col-md-3 col-12 input_box">
              <label for="">Marital Status : </label>
              <select id="marital">
                <option value="Married">Married</option>
                <option value="Single">Single</option>
                <option value="Indian">Widowed</option>
                <option value="Others">Divorced</option>
              </select>
            </div>

            <div class="col-md-4 col-12 input_box">
              <div class="depen_box">
                <label for="name">No of Dependent(s): </label>
                <input type="number" id="no" />
              </div>
            </div>

            <div class="col-md-5 col-12 input_box">
              <div class="depen_box">
                <label for="name">Age(s) : </label>
                <input type="number" id="no" />
                <input type="number" id="no" />
                <input type="number" id="no" />
                <input type="number" id="no" />
                <input type="number" id="no" />
              </div>
            </div>

            <div class="col-md-3 col-12 input_box">
              <label for="">Present House : </label>
              <select id="marital">
                <option value="Own">Own</option>
                <option value="Rented">Rented</option>
                <option value="Mortgaged">Mortgaged</option>
                <option value="Family">Family</option>
              </select>
            </div>

            <div class="col-12 input_box">
              <div class="application_full_box">
                <label for="name">Occupation : </label>
                <input type="text" id="text" />
              </div>
            </div>

            <div class="col-12 input_box">
              <p>*Previous occupation, if retired/ unemployed</p>
              <div class="application_full_box">
                <label for="name">Name of Employer : </label>
                <input type="text" id="emp" />
              </div>
            </div>

            <div class="col-12 input_box">
              <div class="application_full_box">
                <label for="name">Address of Employer : </label>
                <input type="text" id="address" />
              </div>
            </div>

            <div class="col-md-6 input_box">
              <div class="application_full_box">
                <label for="name">Postcode : </label>
                <input type="number" id="post" />
              </div>
            </div>

            <div class="col-12 input_box">
              <h4>APPLICANT’S BANKING ACCOUNT NUMBER</h4>
            </div>

            <div class="col-md-6 col-12 input_box">
              <label for="">Name of Bank : </label>
              <select id="bank">
                <option value="affin">AFFIN BANK BERHAD</option>
                <option value="alliance">ALLIANCE BANK BERHAD</option>
                <option value="ambank">AMBANK BERHAD</option>
                <option value="islam">BANK ISLAM MALAYSIA</option>
                <option value="muamalat">BANK MUAMALAT (MALAYSIA)</option>
                <option value="rakyat">BANK RAKYAT MALAYSIA</option>
                <option value="simpanan">BANK SIMPANAN NASIONAL</option>
                <option value="cimb">CIMB BANK BERHAD</option>
                <option value="cimbislamic">CIMB ISLAMIC BANK BERHAD</option>
                <option value="citi">CITIBANK BERHAD</option>
                <option value="hong">HONG LEONG BANK</option>
                <option value="hsbc">HSBC (M) BERHAD</option>
                <option value="kuwait">KUWAIT FINANCE HOUSE</option>
                <option value="malayan">MALAYAN BANKING BERHAD</option>
                <option value="ocbc">OCBC BANK (M'SIA) BHD</option>
                <option value="public">PUBLIC BANK</option>
                <option value="rhb">RHB BANK</option>
                <option value="standard">STANDARD CHARTED BANK</option>
              </select>
            </div>

            <div class="col-md-6 col-12 input_box">
              <label for="">Account Type : </label>
              <select id="account">
                <option value="savings">Savings</option>
                <option value="current">Current</option>
                <option value="joint">Joint Savings</option>
                <option value="">Joint Current</option>
              </select>
            </div>

            <div class="col-md-10 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Name of Bank : </label>
                <input type="text" id="post" />
              </div>
            </div>

            <div class="col-12 input_box">
              <h4>PROPERTY INFORMATION</h4>
            </div>

            <div class="col-md-6 col-12 input_box">
              <label for="">Property Type : </label>
              <select id="account">
                <option value="bungalow">Bungalow</option>
                <option value="semi">Semi-detached</option>
                <option value="cluster">Cluster</option>
                <option value="terrace">Terrace</option>
                <option value="condominium">Condominium</option>
                <option value="others">Others</option>
              </select>
            </div>

            <div class="row">
              <div class="col-12 input_box">
                <div class="application_full_box">
                  <label for="name">Property address : </label>
                  <input type="text" id="address" />
                </div>
              </div>

              <div class="col-6 input_box">
                <div class="application_full_box">
                  <label for="name">Postcode : </label>
                  <input type="number" id="post" />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Indicative Market Value : </label>
                <label for="rm">RM </label>
                <input type="number" id="value" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="value_box">
                <label for="date">Valuation Date :</label>
                <input type="date" id="valuation" max="9999-12-31" />
              </div>
            </div>

            <div class="col-md-8 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Applicant Expected Market Value : </label>
                <label for="rm">RM </label>
                <input type="number" id="value" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Sale & Purchase Price : </label>
                <label for="rm">RM </label>
                <input type="number" id="value" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="value_box">
                <label for="date">S & P Date :</label>
                <input type="date" max="9999-12-31" id="valuation" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <label for="">Tenure of Property Title : </label>
              <select id="tenure">
                <option value="freehold">Freehold</option>
                <option value="leasehold">Leasehold</option>
              </select>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="value_box">
                <label for="date">( Expiry Date of Lease) :</label>
                <input type="date" max="9999-12-31" id="valuation" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Build up area (in sq) : </label>
                <input type="number" id="tel" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Land area (in sq) : </label>
                <input type="number" id="telNo" />
              </div>
            </div>

            <div class="col-md-6 col-12 input_box">
              <label for="">Property encumbered : </label>
              <input type="radio" id="yes" name="sex_value" value="Yes" />
              <label for="yes">Yes </label>
              <input type="radio" id="no" name="sex_value" value="No" />
              <label for="no">No</label>
            </div>

            <div class="col-md-6 col-12 input_box">
              <div class="application_half_box">
                <label for="name">Name of Bank : </label>
                <input type="text" id="bank" />
              </div>
            </div>

            <div class="col-12 input_box">
              <div class="application_half_box">
                <label for="name">Estimated Outstanding Balance : </label>
                <input type="text" id="address" />
              </div>
            </div>

            <div class="col-md-7 col-12 input_box">
              <label for="">Fire & Home Insurance policy (or its equivalent policy) : </label>
              <input type="radio" id="yes" name="sex_value" value="Yes" />
              <label for="yes">In force </label>
              <input type="radio" id="no" name="sex_value" value="No" />
              <label for="no">Not Available</label>
            </div>

            <div class="row">
              <div class="col-md-6 col-12 input_box">
                <div class="application_half_box">
                  <label for="name">Insurer Name : </label>
                  <input type="number" id="tel" />
                </div>
              </div>

              <div class="col-md-6 col-12 input_box">
                <div class="application_half_box">
                  <label for="name">Period Validity : </label>
                  <input type="number" id="telNo" />
                </div>
              </div>
            </div>

            <div class="col-12 input_box">
              <label for="">Renewal of Fire & Home Insurance : </label>
              <input type="radio" id="yes" name="sex_value" value="Yes" />
              <label for="yes">Self-renewal </label>
              <input type="radio" id="no" name="sex_value" value="No" />
              <label for="no">To be renewed by Cagamas where premium will be deducted from Monthly Payout</label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
