<div class="login-container bg-light">
  <div class="login-box">
    <!-- <div>
      <h6 class="d-inline-block">v{{ version }}</h6>
      <div class="d-inline-block ml-3">
        <app-language-selector></app-language-selector>
      </div>
    </div> -->
    <div class="container-fluid h-100 mobile-min-height">
      <div class="row h-100">
        <div class="col-md-6 bg-white p-2 p-lg-5 h-100">
          <div class="card border-0 width-400">
            <div class="card-body">
              <h4 class="card-title text-center m-0 m-md-3">
                <!--<h1 translate>APP_NAME</h1> -->
                <!--begin: Aside header-->
                <a [routerLink]="['/']" class="flex-column-auto logo-hld">
                  <img src="../../assets/images/ssb-logo.svg" width="180" class="max-h-70px" alt="cagamas" />
                </a>
                <!--end: Aside header-->
                <h2 class="font-size-h1">{{ 'LOGIN.FORM.TITLE' | translate }}</h2>
                <p class="text-muted font-weight-bold">{{ 'LOGIN.FORM.DESCRIPTION' | translate }}</p>
              </h4>
              <form (ngSubmit)="_onSubmit()" [formGroup]="loginForm" novalidate>
                <div class="text-danger m-0 m-md-3" [hidden]="!error || isLoading" translate>
                  {{ errormessage }}
                </div>
                <div class="m-0 m-md-3">
                  <label class="d-block my-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="username"
                      autocomplete="username"
                      [placeholder]="'LOGIN.FORM.FIELD1' | translate"
                    />
                    <span hidden translate>{{ 'LOGIN.FORM.FIELD1' | translate }}</span>
                    <small
                      [hidden]="loginForm.controls['username'].valid || loginForm.controls['username'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'LOGIN.FORM.FIELD1ERROR' | translate }}
                    </small>
                  </label>
                  <label class="d-block mb-3">
                    <div class="position-relative">
                      <input
                        type="{{ !defaultPass ? 'password' : 'text' }}"
                        class="form-control"
                        formControlName="password"
                        autocomplete="current-password"
                        [placeholder]="'LOGIN.FORM.FIELD2' | translate"
                        required
                      />
                      <a (click)="togglepassword('P')" class="password-btn"
                        ><i *ngIf="defaultPass" class="fas fa-eye"></i>
                        <i *ngIf="!defaultPass" class="fas fa-eye-slash"></i
                      ></a>
                    </div>
                    <span hidden translate>{{ 'LOGIN.FORM.FIELD2' | translate }}</span>

                    <small
                      [hidden]="loginForm.controls['password'].valid || loginForm.controls['password'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'LOGIN.FORM.FIELD2ERROR' | translate }}
                    </small>
                  </label>
                  <div class="d-flex justify-content-between">
                    <div class="form-check d-inline-block">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" formControlName="remember" />
                        <span translate class="text-muted">{{ 'LOGIN.FORM.REMEMBER' | translate }}</span>
                      </label>
                    </div>
                    <a (click)="_forgotPassword()" id="" class="text-hover-primary d-inline-block fp-link">{{
                      'LOGIN.FORM.FORGOT' | translate
                    }}</a>
                  </div>
                  <div>
                    <span translate class="text-danger mt-1">{{ 'LOGIN.FORM.NOTE' | translate }}</span>
                  </div>
                </div>

                <div class="m-0 m-md-3 mt-3 mt-md-0 btn-login-hld">
                  <button
                    class="order-md-2 order-1 btn btn-primary btn-custom"
                    type="submit"
                    [disabled]="loginForm.invalid || isLoading"
                  >
                    <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                    <span translate>{{ 'LOGIN.FORM.TITLE' | translate }}</span>
                  </button>

                  <a
                    [routerLink]="['/']"
                    class="order-md-2 order-1 btn btn-primary btn-primary-border btn-custom text-white mt-3 mt-md-0"
                    >{{ 'LOGIN.BUTTON2' | translate }}</a
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 right-login-bg">
          <div
            class="d-flex h-100 flex-row-fluid flex-column justify-content-between px-3 px-md-5 py-3 right-login-inn"
          >
            <!--begin: Aside content-->
            <div class="flex-column-fluid d-flex flex-column justify-content-center h-100">
              <h3 class="login-r-heading mb-md-5 mb-3 text-white">{{ 'LOGIN.BANNER.TITLE' | translate }}</h3>
              <p class="login-r-para text-white opacity-80">
                {{ 'LOGIN.BANNER.SUBTITLE' | translate }}
              </p>
            </div>
            <!--end: Aside content-->
            <!--begin: Aside footer for desktop-->
            <div class="mt-10">
              <div class="footer-txt text-white text-right">{{ 'COMMON.COPYRIGHT' | translate }}</div>
            </div>
            <!--end: Aside footer for desktop-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
