<div class="login-container bg-light">
  <div class="login-box">
    <!-- <div>
      <h6 class="d-inline-block">v{{ version }}</h6>
      <div class="d-inline-block ml-3">
        <app-language-selector></app-language-selector>
      </div>
    </div> -->
    <div class="container-fluid h-100">
      <div class="row h-100 mobile-min-height">
        <div class="col-md-6 bg-white p-2 p-lg-5 h-100">
          <div class="card border-0 width-400">
            <div class="card-body">
              <h4 class="card-title text-center m-0 m-md-3">
                <!--<h1 translate>APP_NAME</h1> -->
                <!--begin: Aside header-->
                <a href="#" class="flex-column-auto logo-hld">
                  <img src="../../assets/images/ssb-logo.svg" width="180" class="max-h-70px" alt="cagamas" />
                </a>
                <!--end: Aside header-->
                <h2 class="font-size-h1">{{ 'LOGIN.FORM.FORGOT' | translate }}</h2>
                <p class="text-muted font-weight-bold">{{ 'FORGOT.FORM.DESCRIPTION' | translate }}</p>
              </h4>
              <form (ngSubmit)="_onSubmit()" [formGroup]="forgotpassForm" autocomplete="off" novalidate>
                <div class="m-3">
                  <label class="d-block my-3">
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      autocomplete="new-email"
                      [placeholder]="'FORGOT.FORM.FIELD1' | translate"
                    />
                    <span hidden translate>{{ 'FORGOT.FORM.FIELD1' | translate }}</span>

                    <small
                      *ngIf="forgotpassForm.controls['email'].errors?.['required'] && !forgotpassForm.controls['email'].untouched"
                      class="text-danger"
                      >{{ 'REGISTRATION.FORM.FIELD2ERROR1' | translate }}</small
                    >
                    <small *ngIf="forgotpassForm.controls['email'].errors?.['pattern']" class="text-danger">{{
                      'REGISTRATION.FORM.FIELD2ERROR1' | translate
                    }}</small>
                  </label>
                </div>

                <div class="m-0 m-md-3 mt-3 mt-md-0 btn-login-hld">
                  <div class="my-2 mr-2 mt-3 mt-mt-0 order-md-1 order-2">
                    <span class="text-muted mr-2">{{ 'REGISTRATION.FORM.NOTE' | translate }}? </span>
                    <a (click)="_redirectLogin()" class="font-weight-bold">{{ 'FORGOT.BUTTON1' | translate }}</a>
                  </div>
                  <button class="order-md-2 order-1 btn btn-primary btn-custom" type="submit">
                    <!-- <i class="fas fa-cog fa-spin"></i> -->
                    <span translate>{{ 'FORGOT.BUTTON2' | translate }}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 right-login-bg">
          <div
            class="d-flex h-100 flex-row-fluid flex-column justify-content-between px-3 px-md-5 py-3 right-login-inn"
          >
            <!--begin: Aside content-->
            <div class="flex-column-fluid d-flex flex-column justify-content-center h-100">
              <h3 class="login-r-heading mb-md-5 mb-3 text-white">
                {{ 'LOGIN.BANNER.TITLE' | translate }}
              </h3>
              <p class="login-r-para text-white opacity-80">
                {{ 'LOGIN.BANNER.SUBTITLE' | translate }}
              </p>
            </div>
            <!--end: Aside content-->
            <!--begin: Aside footer for desktop-->
            <div class="mt-10">
              <div class="footer-txt text-white text-right">{{ 'COMMON.COPYRIGHT' | translate }}</div>
            </div>
            <!--end: Aside footer for desktop-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
