<div class="login-container">
  <div class="login-box">
    <!-- <div>
        <h6 class="d-inline-block">v{{ version }}</h6>
        <div class="d-inline-block ml-3">
          <app-language-selector></app-language-selector>
        </div>
      </div> -->
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-md-12 bg-white p-2 p-md-5 h-100">
          <div class="card border-0 width-800">
            <div class="card-body">
              <h4 class="card-title text-center m-0 m-md-3">
                <!--<h1 translate>APP_NAME</h1> -->
                <!--begin: Aside header-->
                <a [routerLink]="['/']" class="flex-column-auto logo-hld">
                  <img src="../../assets/images/ssb-logo.svg" width="400" class="max-h-70px" alt="cagamas" />
                </a>
                <!--end: Aside header-->
                <h2 class="font-size-h1 text-center mt-5">Digital SSB Application Coming Soon</h2>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
