import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
//import { environment } from '@env/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NationalityValidators } from '../../providers/NationalityValidators';
import { NationalityValidators2 } from '../../providers/NationalityValidators2';
import { DatePipe } from '@angular/common';
import { EligibilityCheckService } from '@app/auth/eligibility--check.service';
import { browserRefresh } from '@app/app.component';
import { UserService } from '@app/auth/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
@UntilDestroy()
@Component({
  selector: 'app-application-part-three',
  templateUrl: './application-part-three.component.html',
  styleUrls: ['./application-part-three.component.scss'],
})
export class ApplicationPartThreeComponent implements OnInit, OnDestroy {
  quote: string | undefined;
  isLoading = false;
  ageerror = false;
  ageerror1 = false;
  applicForm!: FormGroup;
  version: string | null = environment.version;
  nationalitynoterror = false;
  mykaderror = false;
  mykadjointerror = false;
  marriageCertificateerror = false;
  landTitleerror = false;
  akpkNoerror = false;
  receiptserror = false;
  premiumReceiptserror = false;
  rentValuationReporterror = false;
  fireAndInsurancePolicyerror = false;
  ccrisReporterror = false;
  nomineeAckerror = false;
  lumpsumPaymenterror = false;
  formerror = false;
  postcodeerror = false;
  propertypostcodeerror = false;
  userDetailData: any = [];
  sizeerror: string = '';
  typeerror: string = '';
  successapp: string = '';
  successhead: string = '';
  tryagain: string = '';
  // nationality: any = localStorage.getItem('nationality');
  application_part_one_data = localStorage.getItem('part-two');
  joint_part = JSON.parse(this.application_part_one_data ? this.application_part_one_data : '');
  isLoading2 = false;
  UserDocumentList: any;
  DocumentName: any = [];
  filename1: string = 'Choose file';
  filename2: string = 'Choose file';
  filename3: string = 'Choose file';
  filename4: string = 'Choose file';
  filename5: string = 'Choose file';
  filename6: string = 'Choose file';
  filename7: string = 'Choose file';
  filename8: string = 'Choose file';
  filename9: string = 'Choose file';
  filename10: string = 'Choose file';
  filename11: string = 'Choose file';
  filename12: string = 'Choose file';
  filename13: string = 'Choose file';
  filename14: string = 'Choose file';
  filename15: string = 'Choose file';
  filename16: string = 'Choose file';
  filename17: string = 'Choose file';
  filename18: string = 'Choose file';
  filename19: string = 'Choose file';
  filename20: string = 'Choose file';
  myKadHomeOwnerdoc: boolean = false;
  myKadJointOwnerdoc: boolean = false;
  jointdocument: number = 0;
  form3document: number = 0;
  akpkdoc: number = 0;
  receiptsdocument: number = 0;
  form3documenterror: boolean = false;
  receiptForQuitRentdoc: boolean = false;
  receiptForAssesmentdoc: boolean = false;
  receiptForMaintenanceFeedoc: boolean = false;
  premiumReceiptsdoc: boolean = false;
  recentValuationdoc: boolean = false;
  fireAndInsurancePolicydoc: boolean = false;
  ccrisReportdoc: boolean = false;
  nomineeAckdoc: boolean = false;
  form3document10: number = 0;
  constructor(
    private translate: TranslateService,
    private userservice: UserService,
    private eligibilityCheck: EligibilityCheckService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createForm();
    this.setUserCategoryValidators();
    this.usersubmittedapplication();
  }

  public fileDiv = true;

  ngOnInit() {
    this.translate.get('APPLICATION').subscribe((data: any) => {
      this.tryagain = data.FORM1.TRYAGAIN;
      this.sizeerror = data.FORM3.SIZEEXCEED;
      this.typeerror = data.FORM3.TYPEERROR;
      this.successapp = data.FORM3.SUCCESSAPP;
      this.successhead = data.FORM3.SUCCESSHEADER;
    });

    if (browserRefresh == true) {
      this.router.navigate(['/dashboard']);
    }
  }
  private createForm() {
    this.applicForm = this.formBuilder.group({
      salutation: [''],
      applicant_name: ['', Validators.required],
      nric_no: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      res_address: ['', Validators.required],
      postcode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      res_tel: ['', [Validators.minLength(9), Validators.maxLength(11)]],
      hp_tel: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
      dob: ['', Validators.required],
      race: ['', Validators.required],
      nationality: ['', Validators.required],
      sex: ['', Validators.required],
      marital_status: ['', Validators.required],
      relationship: ['', Validators.required],
      salutation2: [''],
      applicant_name2: [''],
      nric_no2: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      res_address2: [''],
      postcode2: ['', [Validators.minLength(5), Validators.maxLength(5)]],
      email2: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      res_tel2: ['', [Validators.minLength(9), Validators.maxLength(11)]],
      hp_tel2: ['', [Validators.minLength(10), Validators.maxLength(11)]],
      dob2: [''],
      race2: [''],
      agree: ['', Validators.required],
      nationality2: [''],
      sex2: [''],
      marital_status2: [''],
      relationship2: [''],

      file1: [''],
      file2: [],
      file3: [],
      file4: [],
      file5: [],
      file6: [],
      file7: [],
      file8: [],
      file9: [],
      file10: [],
      myKadHomeOwner: [],
      myKadJointOwner: [],
      marriageCertificate: [],
      birthCertificate: [],
      landTileOrStrataTitle: [],
      saleAndPurchaseAgreement: [],
      deedOfAssignment: [],
      akpkCertificate: [],
      receiptForQuitRent: [],
      receiptForAssesment: [],
      receiptForMaintenanceFee: [],
      landPremiumReceipt: [],
      rentValuationReport: [],
      fireAndHomeInsurancePolicyOrTakafulPolicy: [],
      ccrisReport: [],
      confirmationLetterFromDoctor: [],
      fullRedemptionStatement: [],
      renovationQuotationOrInvoices: [],
      verification_file: [],
      nomineeAck: [],
      nomineeAckTwo: [],
      newfile1: [],
      newfile2: [],
      newfile3: [],
      newfile4: [],
      newfile5: [],
      newfile6: [],
      newfile7: [],
      newfile8: [],
    });
  }
  setUserCategoryValidators() {
    const institutionControl = this.applicForm.get('usage_of_lumpsum');
    this.applicForm.get('payout_option')?.valueChanges.subscribe((userCategory) => {
      if (userCategory === 'lumpsum') {
        this.applicForm.controls['usage_of_lumpsum'].setValidators([Validators.required]);
      } else {
        institutionControl?.setValidators(null);
      }
      institutionControl?.updateValueAndValidity();
    });
  }
  prevStep = () => {
    this.router.navigate(['/application-part-two']);
  };
  nationalityVerify() {
    // if (this.applicForm.value.nationality == 'Non-Malaysian') {
    //   this.nationalitynoterror = true;
    // } else {
    //   this.nationalitynoterror = false;
    //   var form = new FormData();
    //   form.append('applicationRequest', JSON.stringify(localStorage.getItem('part-two')));
    // }
  }

  apkpk_one() {
    if (this.applicForm.valid) {
      // localStorage.setItem('applcation_type', this.applicForm.value.joint);
      // this.router.navigate(['/application-part-two']);
    } else {
      // localStorage.setItem('applcation_type', this.applicForm.value.joint);
      // this.router.navigate(['/application-part-two']);
      this.markFormGroupTouched(this.applicForm);
    }
  }

  check_date(e: any) {
    const convertAge = new Date(e.target.value);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    var ages = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    var age_dt = new Date(timeDiff);

    var age = Math.abs(age_dt.getUTCFullYear() - 1970);
    if (age < 18) {
      this.ageerror = true;
    } else if (age > 18) {
      this.ageerror = false;
    } else if (age == 18) {
      this.ageerror = false;
    }
  }
  check_date1(e: any) {
    const convertAge = new Date(e.target.value);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    var ages = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    var age_dt = new Date(timeDiff);

    var age = Math.abs(age_dt.getUTCFullYear() - 1970);
    if (age < 18) {
      this.ageerror1 = true;
    } else if (age > 18) {
      this.ageerror1 = false;
    } else if (age == 18) {
      this.ageerror1 = false;
    }
  }
  fileAttached() {
    this.fileDiv = false;
  }

  usersubmittedapplication = async () => {
    await this.userservice.getapplication().subscribe((res) => {
      const _res: any = res.filter((x: any) => {
        return x.orderStatus === 'DRAFT';
      });
      if (_res.length > 0) {
        this.userDetailData = _res;
        console.log(this.userDetailData, 'three this.userDetailData');
        if (this.userDetailData[0].hasOwnProperty('applicationId')) {
          var params = {
            applicationId: this.userDetailData[0].applicationId,
          };
          this.userservice.getUserDocumentList(this.userDetailData[0].applicationId).subscribe(
            (responce) => {
              console.log(responce);
              this.UserDocumentList = responce;
              this.DocumentName = [];
              this.UserDocumentList.forEach((item: any) => {
                const myArray = item.documentName.split('/');
                const word = myArray[1].split('.');
                if (word[0] == 'myKadHomeOwner') {
                  this.filename1 = myArray[1];
                  this.myKadHomeOwnerdoc = true;
                } else if (word[0] == 'myKadJointOwner') {
                  this.filename2 = myArray[1];
                  this.myKadHomeOwnerdoc = true;
                } else if (word[0] == 'marriageCertificate') {
                  this.filename3 = myArray[1];
                  this.myKadHomeOwnerdoc = true;
                } else if (word[0] == 'birthCertificate') {
                  this.filename4 = myArray[1];
                  this.jointdocument = 1;
                } else if (word[0] == 'landTileOrStrataTitle') {
                  this.filename5 = myArray[1];
                  this.form3document = 1;
                } else if (word[0] == 'saleAndPurchaseAgreement') {
                  this.filename6 = myArray[1];
                  this.form3document = 1;
                } else if (word[0] == 'deedOfAssignment') {
                  this.filename7 = myArray[1];
                  this.form3document = 1;
                } else if (word[0] == 'akpkCertificate') {
                  this.filename8 = myArray[1];
                  this.akpkdoc = 1;
                } else if (word[0] == 'receiptForQuitRent') {
                  this.receiptForQuitRentdoc = true;
                  this.receiptsdocument = this.receiptsdocument + 1;
                  this.filename9 = myArray[1];
                } else if (word[0] == 'receiptForAssesment') {
                  this.filename10 = myArray[1];
                  this.receiptForAssesmentdoc = true;
                  this.receiptsdocument = this.receiptsdocument + 1;
                } else if (word[0] == 'receiptForMaintenanceFee') {
                  this.receiptForMaintenanceFeedoc = true;
                  this.filename11 = myArray[1];
                  this.receiptsdocument = this.receiptsdocument + 1;
                } else if (word[0] == 'landPremiumReceipt') {
                  this.filename12 = myArray[1];
                  this.premiumReceiptsdoc = true;
                } else if (word[0] == 'rentValuationReport') {
                  this.filename13 = myArray[1];
                  this.recentValuationdoc = true;
                } else if (word[0] == 'fireAndHomeInsurancePolicyOrTakafulPolicy') {
                  this.filename14 = myArray[1];
                  this.fireAndInsurancePolicydoc = true;
                } else if (word[0] == 'ccrisReport') {
                  this.filename15 = myArray[1];
                  this.ccrisReportdoc = true;
                } else if (word[0] == 'nomineeAck') {
                  this.filename19 = myArray[1];
                  this.nomineeAckdoc = true;
                } else if (word[0] == 'nomineeAckTwo') {
                  this.filename20 = myArray[1];
                  this.nomineeAckdoc = true;
                }else if (word[0] == 'confirmationLetterFromDoctor') {
                  this.filename16 = myArray[1];
                  this.form3document10 = 1;
                } else if (word[0] == 'fullRedemptionStatement') {
                  this.filename17 = myArray[1];
                  this.form3document10 = 1;
                } else if (word[0] == 'renovationQuotationOrInvoices') {
                  this.filename18 = myArray[1];
                  this.form3document10 = 1;
                } else {
                }
              });
              console.log(this.DocumentName);
              // this.UserList = responce;
              // this.allapplicationlist = [];
              // this.UserList.forEach((item: any) => {
              //   this.allapplicationlist.push(item.applicationId);
              // });
            },
            (err) => {
              //console.log(err);
            }
          );
        }
        if (this.userDetailData[0].nomineeDetails.nominee1Details.hasOwnProperty('nominee1DateOfBirth')) {
          const myArray = this.userDetailData[0].nomineeDetails.nominee1Details.nominee1DateOfBirth.split('/');
          const dobformatted = myArray[2] + '-' + myArray[1] + '-' + myArray[0];
          this.applicForm.controls['dob'].setValue(dobformatted);
        }

        this.applicForm.controls['email'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1Email
        );
        this.applicForm.controls['hp_tel'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1HandPhoneNo
        );

        this.applicForm.controls['applicant_name'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1NameAsPerNric
        );
        this.applicForm.controls['nationality'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1Nationality
        );
        this.applicForm.controls['nric_no'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1NricNumber
        );
        this.applicForm.controls['postcode'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1PostCode
        );
        this.applicForm.controls['race'].setValue(this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1Race);
        this.applicForm.controls['relationship'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1Relationship
        );
        this.applicForm.controls['res_tel'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1ResPhoneNo
        );
        this.applicForm.controls['res_address'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1ResidentailAddress
        );
        this.applicForm.controls['salutation'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1Salutation
        );
        this.applicForm.controls['sex'].setValue(this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1Sex);
        this.applicForm.controls['marital_status'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee1Details?.nominee1MaritalStatus
        );

        if (this.userDetailData[0].nomineeDetails.nominee2Details.hasOwnProperty('nominee2DateOfBirth')) {
          const myArray2 = this.userDetailData[0].nomineeDetails.nominee2Details.nominee2DateOfBirth.split('/');
          const dobformatted2 = myArray2[2] + '-' + myArray2[1] + '-' + myArray2[0];
          this.applicForm.controls['dob2'].setValue(dobformatted2);
        }
        this.applicForm.controls['email2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2Email
        );
        this.applicForm.controls['hp_tel2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2HandPhoneNo
        );
        this.applicForm.controls['applicant_name2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2NameAsPerNric
        );
        this.applicForm.controls['nationality2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2Nationality
        );
        this.applicForm.controls['nric_no2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2NricNumber
        );
        if (
          this.userDetailData[0].nomineeDetails.nominee2Details.hasOwnProperty('nominee2PostCode') &&
          this.userDetailData[0].nomineeDetails.nominee2Details.nominee2PostCode != 0
        ) {
          this.applicForm.controls['postcode2'].setValue(
            this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2PostCode
          );
        }
        this.applicForm.controls['race2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2Race
        );
        this.applicForm.controls['res_tel2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2ResPhoneNo
        );
        this.applicForm.controls['res_address2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2ResidentailAddress
        );
        this.applicForm.controls['salutation2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2Salutation
        );
        this.applicForm.controls['sex2'].setValue(this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2Sex);
        this.applicForm.controls['marital_status2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2MaritalStatus
        );
        this.applicForm.controls['relationship2'].setValue(
          this.userDetailData[0].nomineeDetails?.nominee2Details?.nominee2Relationship
        );
      }
    });
  };
  submitForm() {
    this.formerror = false;

    if (this.applicForm.valid) {
      this.nationalitynoterror = false;
      this.joint_part.orderProgress = 'FORM3_DETAILS';
      this.joint_part.nomineeDetails = {
        nominee1Details: {
          nominee1DateOfBirth: new DatePipe('en-US').transform(this.applicForm.value.dob, 'dd/MM/yyyy'),
          nominee1Email: this.applicForm.value.email,
          nominee1HandPhoneNo: this.applicForm.value.hp_tel,
          nominee1MaritalStatus: this.applicForm.value.marital_status,
          nominee1NameAsPerNric: this.applicForm.value.applicant_name,
          nominee1Nationality: this.applicForm.value.nationality,
          nominee1NricNumber: this.applicForm.value.nric_no,
          nominee1PostCode: this.applicForm.value.postcode,
          nominee1Race: this.applicForm.value.race,
          nominee1Relationship: this.applicForm.value.relationship,
          nominee1ResPhoneNo: this.applicForm.value.res_tel,
          nominee1ResidentailAddress: this.applicForm.value.res_address,
          nominee1Salutation: this.applicForm.value.salutation,
          nominee1Sex: this.applicForm.value.sex,
        },
        nominee2Details: {
          nominee2DateOfBirth: new DatePipe('en-US').transform(this.applicForm.value.dob2, 'dd/MM/yyyy'),
          nominee2Email: this.applicForm.value.email2,
          nominee2HandPhoneNo: this.applicForm.value.hp_tel2,
          nominee2MaritalStatus: this.applicForm.value.marital_status2,
          nominee2NameAsPerNric: this.applicForm.value.applicant_name2,
          nominee2Nationality: this.applicForm.value.nationality2,
          nominee2NricNumber: this.applicForm.value.nric_no2,
          nominee2PostCode: this.applicForm.value.postcode2,
          nominee2Race: this.applicForm.value.race2,
          nominee2Relationship: this.applicForm.value.relationship2,
          nominee2ResPhoneNo: this.applicForm.value.res_tel2,
          nominee2ResidentailAddress: this.applicForm.value.res_address2,
          nominee2Salutation: this.applicForm.value.salutation2,
          nominee2Sex: this.applicForm.value.sex2,
        },
      };

      var form = new FormData();

      if (this.applicForm.value.myKadHomeOwner) {
        form.append(
          'myKadHomeOwner',
          this.applicForm.value.myKadHomeOwner,
          this.applicForm.value.myKadHomeOwner['name']
        );

        this.mykaderror = false;
      } else {
        if (this.myKadHomeOwnerdoc == false) {
          this.mykaderror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.myKadJointOwner) {
        form.append(
          'myKadJointOwner',
          this.applicForm.value.myKadJointOwner,
          this.applicForm.value.myKadJointOwner['name']
        );

        this.mykadjointerror = false;
      } else {
        if (this.userDetailData[0].jointApplicant == 'Y') {
          if (this.myKadJointOwnerdoc == false) {
            this.mykadjointerror = true;
            this.formerror = true;
          }
        }
      }
      if (this.applicForm.value.marriageCertificate) {
        form.append(
          'marriageCertificate',
          this.applicForm.value.marriageCertificate,
          this.applicForm.value.marriageCertificate['name']
        );

        this.marriageCertificateerror = false;
      } else {
        if (this.userDetailData[0].jointApplicant == 'Y') {
          if (this.jointdocument == 0) {
            this.marriageCertificateerror = true;
            this.formerror = true;
          }
        }
      }
      if (this.applicForm.value.birthCertificate) {
        form.append(
          'birthCertificate',
          this.applicForm.value.birthCertificate,
          this.applicForm.value.birthCertificate['name']
        );

        //this.marriageCertificateerror = false;
      } else {
        if (this.userDetailData[0].jointApplicant == 'Y') {
          if (this.jointdocument == 0) {
            this.marriageCertificateerror = true;
            this.formerror = true;
          }
        }
      }
      if (this.applicForm.value.landTileOrStrataTitle) {
        form.append(
          'landTileOrStrataTitle',
          this.applicForm.value.landTileOrStrataTitle,
          this.applicForm.value.landTileOrStrataTitle['name']
        );

        this.landTitleerror = false;
      } else {
        if (this.form3document == 0) {
          this.form3documenterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.saleAndPurchaseAgreement) {
        form.append(
          'saleAndPurchaseAgreement',
          this.applicForm.value.saleAndPurchaseAgreement,
          this.applicForm.value.saleAndPurchaseAgreement['name']
        );

        //this.landTitleerror = false
      } else {
        if (this.form3document == 0) {
          this.form3documenterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.deedOfAssignment) {
        form.append(
          'deedOfAssignment',
          this.applicForm.value.deedOfAssignment,
          this.applicForm.value.deedOfAssignment['name']
        );

        //this.landTitleerror = false
      } else {
        if (this.form3document == 0) {
          this.form3documenterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.akpkCertificate) {
        form.append(
          'akpkCertificate',
          this.applicForm.value.akpkCertificate,
          this.applicForm.value.akpkCertificate['name']
        );
        this.akpkNoerror = false;
      } else {
        if (this.akpkdoc == 0) {
          this.akpkNoerror = true;
          this.formerror = true;
        }
      }
      if (this.receiptsdocument < 2) {
        this.receiptserror = true;
        this.formerror = true;
      } else {
        this.receiptserror = false;
      }
      if (this.applicForm.value.receiptForQuitRent) {
        form.append(
          'receiptForQuitRent',
          this.applicForm.value.receiptForQuitRent,
          this.applicForm.value.receiptForQuitRent['name']
        );
        this.receiptserror = false;
      } else {
        // this.receiptserror= true;
        // this.formerror = true;
      }
      if (this.applicForm.value.receiptForAssesment) {
        form.append(
          'receiptForAssesment',
          this.applicForm.value.receiptForAssesment,
          this.applicForm.value.receiptForAssesment['name']
        );
        //this.receiptserror = false;
      } else {
        // this.receiptserror= true;
        // this.formerror = true;
      }
      if (this.applicForm.value.receiptForMaintenanceFee) {
        form.append(
          'receiptForMaintenanceFee',
          this.applicForm.value.receiptForMaintenanceFee,
          this.applicForm.value.receiptForMaintenanceFee['name']
        );
        //this.receiptserror = false;
      } else {
        // this.receiptserror= true;
        // this.formerror = true;
      }
      if (this.applicForm.value.landPremiumReceipt) {
        form.append(
          'landPremiumReceipt',
          this.applicForm.value.landPremiumReceipt,
          this.applicForm.value.landPremiumReceipt['name']
        );
        this.premiumReceiptserror = false;
      } else {
        if (this.premiumReceiptsdoc == false) {
          //this.premiumReceiptserror = true;
          //this.formerror = true;
        }
      }
      if (this.applicForm.value.rentValuationReport) {
        form.append(
          'rentValuationReport',
          this.applicForm.value.rentValuationReport,
          this.applicForm.value.rentValuationReport['name']
        );
        this.rentValuationReporterror = false;
      } else {
        if (this.recentValuationdoc == false) {
          //this.rentValuationReporterror = true;
          //this.formerror = true;
        }
      }
      if (this.applicForm.value.fireAndHomeInsurancePolicyOrTakafulPolicy) {
        form.append(
          'fireAndHomeInsurancePolicyOrTakafulPolicy',
          this.applicForm.value.fireAndHomeInsurancePolicyOrTakafulPolicy,
          this.applicForm.value.fireAndHomeInsurancePolicyOrTakafulPolicy['name']
        );
        this.fireAndInsurancePolicyerror = false;
      } else {
        if (this.fireAndInsurancePolicydoc == false) {
          //this.fireAndInsurancePolicyerror = true;
          //this.formerror = true;
        }
      }
      if (this.applicForm.value.ccrisReport) {
        form.append('ccrisReport', this.applicForm.value.ccrisReport, this.applicForm.value.ccrisReport['name']);
        this.ccrisReporterror = false;
        this.ccrisReportdoc = true;
      } else {
        if (this.ccrisReportdoc == false) {
          this.ccrisReporterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.confirmationLetterFromDoctor) {
        form.append(
          'confirmationLetterFromDoctor',
          this.applicForm.value.confirmationLetterFromDoctor,
          this.applicForm.value.confirmationLetterFromDoctor['name']
        );
        //this.lumpsumPaymenterror= false;
        this.lumpsumPaymenterror = false;
        this.form3document10 = 1;
      } else {
        if (this.joint_part.lumSumUsage != '' && this.form3document10 == 0) {
          this.lumpsumPaymenterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.fullRedemptionStatement) {
        form.append(
          'fullRedemptionStatement',
          this.applicForm.value.fullRedemptionStatement,
          this.applicForm.value.fullRedemptionStatement['name']
        );
        //this.fullRedemptionStatement= false;
        this.lumpsumPaymenterror = false;
        this.form3document10 = 1;
      } else {
        if (this.joint_part.lumSumUsage != '' && this.form3document10 == 0) {
          this.lumpsumPaymenterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.renovationQuotationOrInvoices) {
        form.append(
          'renovationQuotationOrInvoices',
          this.applicForm.value.renovationQuotationOrInvoices,
          this.applicForm.value.renovationQuotationOrInvoices['name']
        );
        //this.fullRedemptionStatement= false;
        this.form3document10 = 1;
        this.lumpsumPaymenterror = false;
      } else {
        if (this.joint_part.lumSumUsage != '' && this.form3document10 == 0) {
          // this.lumpsumPaymenterror= true;
          // this.formerror = true;
          this.lumpsumPaymenterror = true;
          this.formerror = true;
        }
      }
      if (this.applicForm.value.nomineeAck) {
        form.append('nomineeAck', this.applicForm.value.nomineeAck, this.applicForm.value.nomineeAck['name']);
        this.nomineeAckerror = false;
        this.nomineeAckdoc = true;
      } else {
        if (this.nomineeAckdoc == false) {
          this.nomineeAckerror = true;
          this.formerror = true;
        }
      }


if (this.applicForm.value.nomineeAckTwo) {
        form.append('nomineeAckTwo', this.applicForm.value.nomineeAckTwo, this.applicForm.value.nomineeAckTwo['name']);
        this.nomineeAckerror = false;
        this.nomineeAckdoc = true;
      } else {
        if (this.nomineeAckdoc == false) {
          this.nomineeAckerror = true;
          this.formerror = true;
        }
      }

      if (this.formerror == false) {
        this.isLoading = true;
        this.userservice.submit_akpk_one(this.joint_part).subscribe(
          (data) => {
            this.userservice.submit_akpk_two(form, this.joint_part.applicationId).subscribe(
              (data) => {

                window.open('/submitted', '_self');

              //   Swal.fire({
              //     title: this.successhead,
              //     text: this.successapp,
              //     icon: 'success',
              //     showCancelButton: false,
              //     confirmButtonColor: '#3085d6',
              //     confirmButtonText: 'Ok',
              //   }).then((result) => {
              //     if (result.isConfirmed) {
              //       window.open('/dashboard', '_self');
              //       //this.router.navigate(['/dashboard']);
              //     }
              //   });
               },
              (error) => {
                this.isLoading = false;
                Swal.fire({ icon: 'error', title: 'Oops...', text: this.tryagain });
              }
            );
          },
          (error) => {
            this.isLoading = false;
            Swal.fire(this.tryagain, '', 'info');
          }
        );
      }

      // this.router.navigate(['/registration']);
    } else {
      if (this.applicForm.value.nationality == 'Non-Malaysian') {
        this.nationalitynoterror = true;
      } else {
        this.nationalitynoterror = false;
      }
      this.markFormGroupTouched(this.applicForm);
    }
  }
  mykad(event: any) {
    if (event.target.files && event.target.files[0]) {
      const mykadfileSize = event.target.files[0].size / 1024 / 1024;
      if (mykadfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        //
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename1 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            myKadHomeOwner: event.target.files[0],
          });
          console.log(this.applicForm.value);
        } else {
          event.target.value = '';
          //this.filename1 = 'Choose File';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  myKadJointOwner(event: any) {
    // debugger;

    if (event.target.files && event.target.files[0]) {
      const mykadjointfileSize = event.target.files[0].size / 1024 / 1024;
      if (mykadjointfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename2 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            myKadJointOwner: event.target.files[0],
          });
        } else {
          event.target.value = '';
          //this.filename2 = 'Choose File';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  marriageCertificate(event: any) {
    if (event.target.files && event.target.files[0]) {
      const mmarriagefileSize = event.target.files[0].size / 1024 / 1024;
      if (mmarriagefileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename3 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            marriageCertificate: event.target.files[0],
          });
          this.jointdocument = 1;
          this.marriageCertificateerror = false;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  birthCertificate(event: any) {
    if (event.target.files && event.target.files[0]) {
      const birthCertificatefileSize = event.target.files[0].size / 1024 / 1024;
      if (birthCertificatefileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename4 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            birthCertificate: event.target.files[0],
          });
          this.marriageCertificateerror = false;
          this.jointdocument = 1;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  landTitle(event: any) {
    if (event.target.files && event.target.files[0]) {
      const landTitlefileSize = event.target.files[0].size / 1024 / 1024;
      if (landTitlefileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename5 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            landTileOrStrataTitle: event.target.files[0],
          });
          this.form3document = 1;
          this.form3documenterror = false;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  saleAndPurchaseAgreement(event: any) {
    if (event.target.files && event.target.files[0]) {
      const saleAndPurchaseAgreementfileSize = event.target.files[0].size / 1024 / 1024;
      if (saleAndPurchaseAgreementfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename6 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            saleAndPurchaseAgreement: event.target.files[0],
          });
          this.form3document = 1;
          this.form3documenterror = false;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  deedOfAssignment(event: any) {
    if (event.target.files && event.target.files[0]) {
      const deedOfAssignmentfileSize = event.target.files[0].size / 1024 / 1024;
      if (deedOfAssignmentfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename7 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            deedOfAssignment: event.target.files[0],
          });
          this.form3document = 1;
          this.form3documenterror = false;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  akpkNo(event: any) {
    if (event.target.files && event.target.files[0]) {
      const akpkNofileSize = event.target.files[0].size / 1024 / 1024;
      if (akpkNofileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename8 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            akpkCertificate: event.target.files[0],
          });
          this.akpkdoc = 1;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  receipts(event: any) {
    if (event.target.files && event.target.files[0]) {
      const receiptsfileSize = event.target.files[0].size / 1024 / 1024;
      if (receiptsfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename9 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            receiptForQuitRent: event.target.files[0],
          });
          if (this.receiptForQuitRentdoc == false) {
            this.receiptsdocument = this.receiptsdocument + 1;
          }
          if (this.receiptsdocument > 1) {
            this.receiptserror = false;
          }
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  receiptForAssesment(event: any) {
    if (event.target.files && event.target.files[0]) {
      const receiptForAssesmentfileSize = event.target.files[0].size / 1024 / 1024;
      if (receiptForAssesmentfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename10 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            receiptForAssesment: event.target.files[0],
          });
          if (this.receiptForAssesmentdoc == false) {
            this.receiptsdocument = this.receiptsdocument + 1;
          }
          if (this.receiptsdocument > 1) {
            this.receiptserror = false;
          }
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  receiptForMaintenanceFee(event: any) {
    if (event.target.files && event.target.files[0]) {
      const receiptForMaintenanceFeefileSize = event.target.files[0].size / 1024 / 1024;
      if (receiptForMaintenanceFeefileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename11 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            receiptForMaintenanceFee: event.target.files[0],
          });
          if (this.receiptForMaintenanceFeedoc == false) {
            this.receiptsdocument = this.receiptsdocument + 1;
          }
          if (this.receiptsdocument > 1) {
            this.receiptserror = false;
          }
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  premiumReceipts(event: any) {
    if (event.target.files && event.target.files[0]) {
      const premiumReceiptsfileSize = event.target.files[0].size / 1024 / 1024;
      if (premiumReceiptsfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename12 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            landPremiumReceipt: event.target.files[0],
          });
          this.premiumReceiptserror = false;
          this.premiumReceiptsdoc = true;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  rentValuationReport(event: any) {
    if (event.target.files && event.target.files[0]) {
      const rentValuationfileSize = event.target.files[0].size / 1024 / 1024;
      if (rentValuationfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename13 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            rentValuationReport: event.target.files[0],
          });
          this.rentValuationReporterror = false;
          this.recentValuationdoc = true;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  fireAndInsurancePolicy(event: any) {
    if (event.target.files && event.target.files[0]) {
      const firefileSize = event.target.files[0].size / 1024 / 1024;
      if (firefileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename14 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            fireAndHomeInsurancePolicyOrTakafulPolicy: event.target.files[0],
          });
          this.fireAndInsurancePolicyerror = false;
          this.fireAndInsurancePolicydoc = true;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  ccrisReport(event: any) {
    if (event.target.files && event.target.files[0]) {
      const ccrisReportfileSize = event.target.files[0].size / 1024 / 1024;
      if (ccrisReportfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename15 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            ccrisReport: event.target.files[0],
          });
          this.ccrisReportdoc = true;
          this.ccrisReporterror = false;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  nomineeAck(event: any) {
    if (event.target.files && event.target.files[0]) {
      const nomineeAckfileSize = event.target.files[0].size / 1024 / 1024;
      if (nomineeAckfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename19 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            nomineeAck: event.target.files[0],
          });
          this.nomineeAckdoc = true;
          this.nomineeAckerror = false;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }

 nomineeAckTwo(event: any) {
    if (event.target.files && event.target.files[0]) {
      const nomineeAckfileSize = event.target.files[0].size / 1024 / 1024;
      if (nomineeAckfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename20 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            nomineeAckTwo: event.target.files[0],
          });
          this.nomineeAckdoc = true;
          this.nomineeAckerror = false;
        } 
        else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }

  lumpsumPayment(event: any) {
    if (event.target.files && event.target.files[0]) {
      const lumpsumfileSize = event.target.files[0].size / 1024 / 1024;
      if (lumpsumfileSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename16 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            confirmationLetterFromDoctor: event.target.files[0],
          });
          this.lumpsumPaymenterror = false;
          this.form3document10 = 1;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  fullRedemptionStatement(event: any) {
    if (event.target.files && event.target.files[0]) {
      const fullRedemptionStatementSize = event.target.files[0].size / 1024 / 1024;
      if (fullRedemptionStatementSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename17 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            fullRedemptionStatement: event.target.files[0],
          });
          this.lumpsumPaymenterror = false;
          this.form3document10 = 1;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  renovation(event: any) {
    if (event.target.files && event.target.files[0]) {
      const renovationSize = event.target.files[0].size / 1024 / 1024;
      if (renovationSize > 1) {
        event.target.value = '';
        Swal.fire({ icon: 'warning', title: 'Warning...', text: this.sizeerror });
      } else {
        var name = event.target.files[0]['name'];
        const extension = name.split('.').pop();
        if (extension == 'jpg' || extension == 'jpeg' || extension == 'pdf') {
          this.filename18 = event.target.files[0]['name'];
          this.applicForm.patchValue({
            renovationQuotationOrInvoices: event.target.files[0],
          });
          this.lumpsumPaymenterror = false;
          this.form3document10 = 1;
        } else {
          event.target.value = '';
          Swal.fire({ icon: 'warning', title: 'Warning...', text: this.typeerror });
        }
      }
    } else {
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
  post_code_validate() {
    this.eligibilityCheck.postcode(this.applicForm.value.postcode).subscribe(
      (data) => {},
      (error) => {
        if (error.status == 200) {
          this.postcodeerror = false;
        } else {
          this.postcodeerror = true;
          this.applicForm.controls['postcode'].setValue('');
        }
      }
    );
  }
  post_code_validate2() {
    this.eligibilityCheck.postcode(this.applicForm.value.postcode2).subscribe(
      (data) => {},
      (error) => {
        if (error.status == 200) {
          this.propertypostcodeerror = false;
        } else {
          this.propertypostcodeerror = true;
          this.applicForm.controls['postcode2'].setValue('');
        }
      }
    );
  }
  saveForm() {
    this.joint_part.orderProgress = 'FORM3_DETAILS';
    this.joint_part.nomineeDetails = {
      nominee1Details: {
        nominee1DateOfBirth: new DatePipe('en-US').transform(this.applicForm.value.dob, 'dd/MM/yyyy'),
        nominee1Email: this.applicForm.value.email,
        nominee1HandPhoneNo: this.applicForm.value.hp_tel,
        nominee1MaritalStatus: this.applicForm.value.marital_status ? this.applicForm.value.marital_status : '',
        nominee1NameAsPerNric: this.applicForm.value.applicant_name,
        nominee1Nationality: this.applicForm.value.nationality,
        nominee1NricNumber: this.applicForm.value.nric_no,
        nominee1PostCode: this.applicForm.value.postcode,
        nominee1Race: this.applicForm.value.race,
        nominee1Relationship: this.applicForm.value.relationship,
        nominee1ResPhoneNo: this.applicForm.value.res_tel,
        nominee1ResidentailAddress: this.applicForm.value.res_address,
        nominee1Salutation: this.applicForm.value.salutation,
        nominee1Sex: this.applicForm.value.sex,
      },
      nominee2Details: {
        nominee2DateOfBirth: new DatePipe('en-US').transform(this.applicForm.value.dob2, 'dd/MM/yyyy'),
        nominee2Email: this.applicForm.value.email2,
        nominee2HandPhoneNo: this.applicForm.value.hp_tel2,
        nominee2MaritalStatus: this.applicForm.value.marital_status2 ? this.applicForm.value.marital_status2 : '',
        nominee2NameAsPerNric: this.applicForm.value.applicant_name2,
        nominee2Nationality: this.applicForm.value.nationality2,
        nominee2NricNumber: this.applicForm.value.nric_no2,
        nominee2PostCode: this.applicForm.value.postcode2,
        nominee2Race: this.applicForm.value.race2,
        nominee2Relationship: this.applicForm.value.relationship2,
        nominee2ResPhoneNo: this.applicForm.value.res_tel2,
        nominee2ResidentailAddress: this.applicForm.value.res_address2,
        nominee2Salutation: this.applicForm.value.salutation2,
        nominee2Sex: this.applicForm.value.sex2,
      },
    };
    this.isLoading2 = true;
    console.log(this.joint_part);
    this.userservice.submit_akpk_one(this.joint_part).subscribe(
      (data) => {
        this.isLoading2 = false;
        localStorage.setItem('part-one', JSON.stringify(data));
      },
      (error) => {
        this.isLoading = false;
        Swal.fire(this.tryagain, '', 'info');
      }
    );
  }
getackform(){
    // window.open(environment.apiUrl + 'api/download-attachment/akpk.docx', '');
     if (localStorage.getItem('language') == 'en') {
       this.userservice.getackFormEN();
    } else {
       this.userservice.getackFormBM();
     }
}

  getdocumentlist = async () => {};
  ngOnDestroy() {
    this.saveForm();
  }
}
