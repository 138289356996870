import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { RegistrationComponent } from './registration.component';
import { ForgotPassComponent } from './forgot-pass.component';
import { LandingComponent } from './landing.component';
import { EligibilityCheckComponent } from './eligibility-check/eligibility-check.component';
import { RegisterSuccessfullyComponent } from './register-successfully/register-successfully.component';
import { VerifyuserComponent } from './verifyuser/verifyuser.component';
import { VerifiedSuccessfullyComponent } from './verified-successfully/verified-successfully.component';
import { ForgotEmailComponent } from './forgot-email/forgot-email.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, NgbModule, I18nModule, AuthRoutingModule, FormsModule],
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ForgotPassComponent,
    LandingComponent,
    EligibilityCheckComponent,
    RegisterSuccessfullyComponent,
    VerifyuserComponent,
    ComingsoonComponent,
    VerifiedSuccessfullyComponent,
    ForgotEmailComponent,
  ],
  bootstrap: [ForgotPassComponent],
})
export class AuthModule {}
