import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from './authentication.service';
import { CustomValidators } from '../../providers/CustomValidators';
import Swal from 'sweetalert2';
import { EligibilityCheckService } from './eligibility--check.service';
import { browserRefresh } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
@UntilDestroy()
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  error: string | undefined;
  registrationForm!: FormGroup;
  email = '';
  emailverify: any;
  isLoading = false;
  unauthorizederr: string = '';
  tryagain: string = '';
  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private verifyemailservice: AuthenticationService,
    private authenticationService: AuthenticationService,
    private eligibilityCheck: EligibilityCheckService
  ) {
    this.createForm();
  }
  eligibilityCreteria: any;
  eligibilityObject: any;
  ngOnInit(): void {
    this.eligibilityCreteria = localStorage.getItem('eligibilityCreteria');
    this.eligibilityCreteria = JSON.parse(this.eligibilityCreteria);

    if (browserRefresh == true) {
      this.router.navigate(['/']);
    }
    this.translate.get('REGISTRATION').subscribe((data: any) => {
      this.unauthorizederr = data.FORM.UNAUTHORIZED;
      this.tryagain = data.FORM.TRYAGAIN;
    });
  }

  private createForm() {
    this.registrationForm = this.formBuilder.group(
      {
        fullname: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: CustomValidators('password', 'confirmPassword'),
      }
    );
  }
  registration() {
    this.isLoading = true;
    if (this.registrationForm.valid) {
      var body = {
        fullname: this.registrationForm.value.fullname,
        email: this.registrationForm.value.email,
        phoneNo: this.registrationForm.value.phoneNo,
        password: this.registrationForm.value.password,
        confirmPassword: this.registrationForm.value.confirmPassword,
        url: environment.hostUrl + '/verifyuser/' + this.registrationForm.value.email,
      };
      this.eligibilityObject = {
        email: this.registrationForm.value.email,
        ssbEligibilityCriteriaPojos: this.eligibilityCreteria,
      };
      this.authenticationService
        .register(body)
        .pipe(
          finalize(() => {
            this.registrationForm.markAsPristine();
            this.isLoading = false;
          }),
          untilDestroyed(this)
        )
        .subscribe(
          (credentials: any) => {
            this.email = credentials.email;
            localStorage.setItem('RegisteredEmail', this.email);
            //this.router.navigate(['/register-successfully']);
            // this.verify_emailLink();
            if (credentials.hasOwnProperty('id')) {
              this.eligibilityCheck.eligibilityCreteria(this.email, this.eligibilityObject).subscribe(
                (data) => {
                  let eligibilityDetails = data.eligibilityDetails;

                  if (data == 'success') {
                    this.router.navigate(['/register-successfully']);
                  }
                },
                (error) => {
                  if (error.statusText == 'OK') {
                    this.router.navigate(['/register-successfully']);
                    //Swal.fire({ icon: 'error', title: 'Oops...', text: 'User with same email exists' });
                  } else {
                    Swal.fire({ icon: 'error', title: 'Oops...', text: error.error.message });
                  }
                }
              );
            }
          },
          (error) => {
            if (error.statusText == 'Unauthorized' || error.statusText == 'OK') {
              Swal.fire({ icon: 'error', title: 'Oops...', text: 'User with same email exists' });
            } else {
              Swal.fire({ icon: 'error', title: 'Oops...', text: error.error.message });
            }
          }
        );
    } else {
      this.markFormGroupTouched(this.registrationForm);
    }
  }

  verify_emailLink() {
    // this.EligibilityCheck.eligibilityCreteria(localStorage.getItem('RegisteredEmail'), body).subscribe((data) => {
    //   let eligibilityDetails = data.eligibilityDetails;
    //   localStorage.setItem('eligibilityDetails', eligibilityDetails);
    //   console.log(data);
    //   if (data.error == '200') {
    //     Swal.fire({ icon: 'success', title: 'Success...', text: 'Eligibility Creteria Matched. Please Register' });
    //     this.router.navigate(['/registration']);
    //   }
    // },
    // (error) => {
    //   console.log(error);
    //   Swal.fire({ icon: 'error', title: 'Oops...', text: 'Please Try again later!' });
    // }
    // );
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
  _defaultPass: boolean = false;
  _secondryPass: boolean = false;
  togglepassword = (_requestFrom: string) => {
    if (_requestFrom == 'P') {
      this._defaultPass = !this._defaultPass;
    }
    if (_requestFrom == 'S') {
      this._secondryPass = !this._secondryPass;
    }
  };
}
