import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials, CredentialsService } from './credentials.service';
import { environment } from '../../../src/environments/environment';
export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  httpHeaderOptions: { headers: HttpHeaders };
  httpHeaders: any;
  logoutheader: { headers: HttpHeaders };

  constructor(private credentialsService: CredentialsService, private _http: HttpClient) {
    const credentials = this.credentialsService.credentials;
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    this.httpHeaderOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    this.logoutheader = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + credentials?.token + '',
      }),
    };
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  // login(context: LoginContext): Observable<Credentials> {
  //   // Replace by proper authentication call
  //   const data = {
  //     username: context.username,
  //     token: '123456',
  //   };
  //   this.credentialsService.setCredentials(data, context.remember);
  //   return of(data);
  // }

  login(Username: string, Password: string): Observable<any> {
    // const headers = new HttpHeaders();
    // .set('content-type', 'application/json')
    // .set('Access-Control-Allow-Origin', '*');
    this.credentialsService.setCredentials();
    //localStorage.clear();
    localStorage.removeItem('username');
    localStorage.removeItem('bearer');
    localStorage.removeItem('credentials');
    return this._http.post<any[]>('/api/login', {
      email: Username,
      password: Password,
    });
  }

  register(body: any): Observable<any> {
    return this._http.post<any[]>('/api/register', body, this.httpHeaderOptions);
  }

  forgot_pass_email(body: any): Observable<any> {
    // return this._http.post<any[]>(
    //   '/api/forgot-password-email?email=' + params.email + '&url=' + params.url,
    //   this.httpHeaderOptions
    // );
    return this._http.post<any[]>('/api/forgot-password-email', body, this.httpHeaderOptions);
  }

  forgot_password(body: any): Observable<any> {
    return this._http.post<any[]>('/api/forgot-password', body, this.httpHeaderOptions);
  }

  resetPassword(body: any): Observable<any> {
    return this._http.post<any[]>('/api/reset-password', body, this.httpHeaderOptions);
  }

  emailVerify(email: any): Observable<any> {
    return this._http.post<any[]>('/api/verify-account?email=' + email, this.httpHeaders);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  // logout(): Observable<boolean> {
  //   // Customize credentials invalidation here
  //   this.credentialsService.setCredentials();
  //   return of(true);
  // }

  logout(): Observable<any> {
    return this._http.post<any[]>('/api/logout', this.logoutheader);
  }
}
