import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubmittedPageRoutingModule } from './submittedpage-routing.module';
import { submittedpageComponent } from './submittedpage.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [submittedpageComponent],
  imports: [CommonModule, SubmittedPageRoutingModule, ReactiveFormsModule, TranslateModule],
})
export class SubmittedPageModule {}
