<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/css/all.min.css"
  integrity="sha512-3M00D/rn8n+2ZVXBO9Hib0GKNpkm8MSUU/e2VNthDyBYxKWG+BftNYYcuEjXlyrSO637tidzMBXfE7sQm0INUg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>

<!-- Header Start-->
<header id="header" class="header" role="banner" aria-label="Site header">
  <nav class="navbar navbar-expand-lg" id="navbar-main">
    <a [routerLink]="['/']" title="Home" rel="home" class="navbar-brand"
      ><img src="../../../assets/images/cagamas-logo-new01.jpg" alt="Home" class="img-fluid d-inline-block align-top"
    /></a>
    <div class="burger-wrapper">
      <button class="navbar-toggler burger-button" onclick="mobileMenu()">
        <span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span
        ><span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="CollapsingNavbar">
      <nav
        role="navigation"
        aria-labelledby="block-cagamas-main-menu-menu"
        id="block-cagamas-main-menu"
        class="block block-menu navigation menu--main"
      >
        <div class="mobile-menu-top">
          <ul class="menu">
            <li class="nav-item menu-item lan-menu" style="display: flex">
              <a
                href="javascript:void(0)"
                style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a"
                (click)="switch('en')"
                >EN</a
              ><span style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400">|</span
              ><a
                href="javascript:void(0)"
                (click)="switch('ms')"
                style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400"
                >BM</a
              >
            </li>
          </ul>
          <a [routerLink]="['/']" title="Home" rel="home" class="navbar-brand-mobile"
            ><img
              src="../../../assets/images/cagamas-logo-new01.jpg"
              alt="Home"
              class="img-fluid d-inline-block align-top"
          /></a>
        </div>
        <ul data-region="primary_menu" id="block-cagamas-main-menu" class="clearfix nav navbar-nav menu">
          <li class="nav-item menu-item">
            <a [routerLink]="['/eligibility-criteria']" class="nav-link"
              ><span>{{ 'LANDING.MENU3' | translate }} </span></a
            >
          </li>
          <li class="nav-item menu-item">
            <a href="https://www.ssb.cagamas.com.my/" target="_blank" class="nav-link"
              ><span>{{ 'LANDING.MENU2' | translate }}</span></a
            >
          </li>
          <li class="nav-item menu-item" style="display: flex">
            <a
              href="javascript:void(0)"
              style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a"
              (click)="switch('en')"
              >EN</a
            ><span style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400">|</span
            ><a
              href="javascript:void(0)"
              (click)="switch('ms')"
              style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400"
              >BM</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </nav>
</header>
<!-- Header End-->

<!-- Body Start-->
<div class="main-wrapper">
  <div class="banner-wrapper first last">
    <div class="row no-gutters m-0">
      <div class="col-md-6 col-12">
        <div class="container">
          <div class="page-title-wrap">
            <h1 class="page-title">{{ 'LANDING.BANNER.TITLE' | translate }}</h1>
            <div class="subtitle">{{ 'LANDING.BANNER.SUBTITLE' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="banner-img">
          <img
            src="../../../assets/images/banner-1.png"
            width="1000"
            height="585"
            alt="Eligibility Criteria"
            loading="lazy"
            class="image-style-wide"
          />
        </div>
      </div>
    </div>
  </div>
  <section class="py-5 light-blue-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="heading mb-md-5 mb-md-2 mt-md-4 mt-md-2 text-center">
            {{ 'LANDING.APPLICATIONPROCESS.TITLE' | translate }}
          </h2>

          <ul class="steps mb-1">
            <li class="active">
              <p class="point" [routerLink]="['/eligibility-criteria']">
                Step 1<br />{{ 'LANDING.APPLICATIONPROCESS.STEP1' | translate }}
              </p>
              <!-- <p>{{ 'LANDING.APPLICATIONPROCESS.STEP1' | translate }}</p> -->
            </li>

            <!-- <li class="active">
              <p>{{ 'LANDING.APPLICATIONPROCESS.STEP2' | translate }}</p>
            </li> -->

            <li class="active">
              <p [routerLink]="['/login']">Step 2<br />{{ 'LANDING.APPLICATIONPROCESS.STEP3' | translate }}</p>
            </li>

            <li class="active">
              <p style="white-space: pre-line">Step 3<br />{{ 'LANDING.APPLICATIONPROCESS.STEP4' | translate }}</p>
            </li>

            <li class="active">
              <!-- <p>{{ 'LANDING.APPLICATIONPROCESS.STEP5' | translate }}</p> -->
              <p style="white-space: pre-line">Step 4<br />{{ 'LANDING.APPLICATIONPROCESS.STEP5' | translate }}</p>
            </li>

            <!-- <li class="active">
              <p>{{ 'LANDING.APPLICATIONPROCESS.STEP6' | translate }}</p>
            </li> -->

            <li class="active">
              <!-- <p>{{ 'LANDING.APPLICATIONPROCESS.STEP7' | translate }}</p> -->

              <p class="point" [routerLink]="['/login']">
                Step 5<br />{{ 'LANDING.APPLICATIONPROCESS.STEP7' | translate }}
              </p>
            </li>
          </ul>
        </div>
        <b class="bnoted" style="white-space: pre-line">{{ 'LANDING.APPLICATIONPROCESS.BNOTED' | translate }}</b>
      </div>
    </div>
  </section>
  <article class="main-top-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 col-12">
          <h2 class="heading mb-5">{{ 'LANDING.ABOUT.TITLE' | translate }}</h2>
          <p style="text-align: center; white-space: pre-line">{{ 'LANDING.ABOUT.DESCRIPTION' | translate }}</p>
          <ul class="list">
            <li>
              {{ 'LANDING.ABOUT.LI1' | translate }}
            </li>
            <li>
              {{ 'LANDING.ABOUT.LI2' | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </article>

  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-12">
          <h2 class="heading text-center">{{ 'LANDING.FEATURES.TITLE' | translate }}</h2>
        </div>
      </div>
      <div class="card-list mt-4">
        <div class="row position-relative">
          <div class="col-lg-3 mb-md-5 mb-md-2 mt-md-4 mt-md-2">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/lifetime.png" alt="" class="img-responsive" /></div>
              <h3>{{ 'LANDING.FEATURES.FEATURETITLE1' | translate }}</h3>
              <p>{{ 'LANDING.FEATURES.FEATUREDESCRIPTION1' | translate }}</p>
            </div>
          </div>
          <div class="col-lg-3 mb-md-5 mb-md-2 mt-md-4 mt-md-2">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/no repayment.png" alt="" class="img-responsive" /></div>
              <h3>{{ 'LANDING.FEATURES.FEATURETITLE2' | translate }}</h3>
              <p>{{ 'LANDING.FEATURES.FEATUREDESCRIPTION2' | translate }}</p>
            </div>
          </div>
          <div class="col-lg-3 mb-md-5 mb-md-2 mt-md-4 mt-md-2">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/residing.png" alt="" class="img-responsive" /></div>
              <h3>{{ 'LANDING.FEATURES.FEATURETITLE3' | translate }}</h3>
              <p>{{ 'LANDING.FEATURES.FEATUREDESCRIPTION3' | translate }}</p>
            </div>
          </div>
          <div class="col-lg-3 mb-md-5 mb-md-2 mt-md-4 mt-md-2">
            <div class="graphical-item">
              <div class="image"><img src="../../assets/images/non-recourse.png" alt="" class="img-responsive" /></div>
              <h3>{{ 'LANDING.FEATURES.FEATURETITLE4' | translate }}</h3>
              <p>{{ 'LANDING.FEATURES.FEATUREDESCRIPTION4' | translate }}</p>
            </div>
          </div>
          <!-- <div class="col-lg-12 col-md-12 col-sm-6 mt-4">
            <div class="graphical-item">
              <div class="row">
                <div class="col-md-1">
                  <div class="image mt-2">
                    <img src="../../assets/images/residing.png" alt="" class="img-responsive" />
                  </div>
                </div>
                <div class="col-md-2">
                  <h3 class="mt-4">{{ 'LANDING.FEATURES.FEATURETITLE3' | translate }}</h3>
                </div>
                <div class="col-md-9">
                  <p>
                    {{ 'LANDING.FEATURES.FEATUREDESCRIPTION3' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-lg-12 col-md-12 col-sm-6 mt-4">
            <div class="graphical-item">
              <div class="row">
                <div class="col-md-1">
                  <div class="image mt-2">
                    <img src="../../assets/images/non-recourse.png" alt="" class="img-responsive" />
                  </div>
                </div>
                <div class="col-md-2">
                  <h3 class="mt-4">{{ 'LANDING.FEATURES.FEATURETITLE4' | translate }}</h3>
                </div>
                <div class="col-md-9">
                  <p>
                    {{ 'LANDING.FEATURES.FEATUREDESCRIPTION4' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="bp-bg">
    <div class="para-general">
      <div class="container">
        <h2 class="heading text-center">{{ 'COMMON.EC' | translate }}</h2>
      </div>
    </div>

    <div class="card-list mt-5">
      <div class="container">
        <div class="row row-graphical">
          <div class="col-sm-6 col-xs-12 col-graphical mb-5">
            <div class="graphical-item">
              <div class="image">
                <img src="../../assets/images/eligible-criteria-borrower.png" alt="Borrower" class="img-responsive" />
              </div>
              <h3>{{ 'LANDING.CRITERIA.BORROWERS.TITLE' | translate }}</h3>
              <ul class="list">
                <li>
                  {{ 'LANDING.CRITERIA.BORROWERS.LI1' | translate }}
                </li>
                <li>
                  {{ 'LANDING.CRITERIA.BORROWERS.LI2' | translate }} <br />
                  {{ 'LANDING.CRITERIA.BORROWERS.LI3' | translate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-xs-12 col-graphical mb-5">
            <div class="graphical-item">
              <div class="image">
                <img src="../../assets/images/eligible-criteria-property.png" alt="Property" class="img-responsive" />
              </div>
              <h3>{{ 'LANDING.CRITERIA.PROPERTY.TITLE' | translate }}</h3>
              <ul class="list">
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI1' | translate }}</li>
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI2' | translate }}</li>
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI3' | translate }}</li>
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI4' | translate }}</li>
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI5' | translate }}</li>
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI6' | translate }}</li>
                <li>{{ 'LANDING.CRITERIA.PROPERTY.LI7' | translate }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-5 bo-rn-hld">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2 class="heading mt-4 mb-4">{{ 'LANDING.CUSTOMER OBLIGATION.TITLE' | translate }}</h2>
          <ul class="list">
            <li>{{ 'LANDING.CUSTOMER OBLIGATION.LI1' | translate }}</li>
            <li>{{ 'LANDING.CUSTOMER OBLIGATION.LI2' | translate }}</li>
            <li>{{ 'LANDING.CUSTOMER OBLIGATION.LI3' | translate }}</li>
            <li>{{ 'LANDING.CUSTOMER OBLIGATION.LI4' | translate }}</li>
          </ul>
          <h2 class="heading mt-4 mb-4">{{ 'LANDING.ROLEOFNOMINEE.TITLE' | translate }}</h2>
          <ul class="list">
            <li>{{ 'LANDING.ROLEOFNOMINEE.LI1' | translate }}</li>
            <li>{{ 'LANDING.ROLEOFNOMINEE.LI2' | translate }}</li>
            <li>{{ 'LANDING.ROLEOFNOMINEE.LI3' | translate }}</li>
          </ul>
        </div>
        <div class="col-md-6">
          <img src="../../assets/images/ssb-get-peace.png" alt="" class="img-responsive" />
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Body End-->

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
