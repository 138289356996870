import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { browserRefresh } from '@app/app.component';
import Swal from 'sweetalert2';
import { EligibilityCheckService } from '../eligibility--check.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-register-successfully',
  templateUrl: './register-successfully.component.html',
  styleUrls: ['./register-successfully.component.scss'],
})
export class RegisterSuccessfullyComponent implements OnInit {
  email = localStorage.getItem('RegisteredEmail');
  message: string = '';
  constructor(
    public translate: TranslateService,
    private eligibilityCheck: EligibilityCheckService,
    private router: Router
  ) {}
  eligibilityCreterias: any;
  eligibilityCreteria: any;
  eligibilityObject: any;
  ngOnInit(): void {
    this.eligibilityCreterias = localStorage.getItem('eligibilityCreteria');
    this.eligibilityCreteria = JSON.parse(this.eligibilityCreterias);
    this.eligibility_check();
    this.translate.get('REGISTERSUCCESS').subscribe((data: any) => {
      this.message = data;
    });
    this.eligibilityCreterias = localStorage.getItem('eligibilityCreteria');
    if (browserRefresh == true) {
      this.router.navigate(['/']);
    }
  }
  eligibility_check() {
    this.email = localStorage.getItem('RegisteredEmail');
    this.eligibilityObject = {
      email: this.email,
      ssbEligibilityCriteriaPojos: this.eligibilityCreteria,
    };
    this.eligibilityCheck.eligibilityCreteria(this.email, this.eligibilityObject).subscribe(
      (data) => {
        let eligibilityDetails = data.eligibilityDetails;

        if (data == 'success') {
          console.log('sccess');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
