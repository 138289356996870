import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from './credentials.service';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EligibilityCheckService {
  httpHeaderOptions: { headers: HttpHeaders };
  httpHeaderOptions2: { headers: HttpHeaders };
  applicationheader: { headers: HttpHeaders };
  httpHeaders: any;
  auth_token = this.credentialsService.credentials?.token;
  eligibilityCreteriaOption: any;
  applicationHeader: { headers: HttpHeaders };
  applicationToken: any;

  constructor(private credentialsService: CredentialsService, private _http: HttpClient) {
    const credentials = this.credentialsService.credentials;
    if (localStorage.getItem('bearer') == null || localStorage.getItem('bearer') == undefined) {
      this.applicationToken = this.credentialsService.credentials?.token;
    } else {
      this.applicationToken = localStorage.getItem('bearer');
    }
    console.log(this.applicationToken);
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    this.httpHeaderOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      }),
    };
    this.eligibilityCreteriaOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // Authorization: 'Bearer ' + this.auth_token,
      }),
    };
    this.httpHeaderOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.auth_token + '',
      }),
    };
    this.applicationHeader = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('bearer'),
      }),
    };
    this.applicationheader = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + credentials?.token + '',
      }),
    };
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  // login(context: LoginContext): Observable<Credentials> {
  //   // Replace by proper authentication call
  //   const data = {
  //     username: context.username,
  //     token: '123456',
  //   };
  //   this.credentialsService.setCredentials(data, context.remember);
  //   return of(data);
  // }

  // eligibilityCreteria(email: any, body: any): Observable<any> {
  //   return this._http.post<any[]>(
  //     '/api/customer-eligibility-criteria?email=' + email,
  //     body,
  //     this.eligibilityCreteriaOption
  //   );
  // }
  eligibilityCreteria(email: any, body: any): Observable<any> {
    return this._http.post<any[]>('/api/customer-eligibility-criteria', body, this.eligibilityCreteriaOption);
  }

  scheduleAKPK(email: any, url: any): Observable<any> {
    return this._http.post<any[]>('/api/forgot-password-email?email=' + email + '&url=' + url, this.httpHeaderOptions);
  }
  getAKPK(): Observable<any> {
    return this._http.get<any[]>('/api/download-attachment/akpk.docx', this.httpHeaderOptions);
  }

  verifyPostal(postcode: any): Observable<any> {
    // const headers = new Headers()
    //   .set('Authorization', 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzaGFoYmF6ei5hcnFhbUBnbWFpbC5jb20iLCJleHAiOjE2NjM4NjIzOTksImlhdCI6MTY2MzgzNjg1Mn0.fKxL_HDrHR3hQ4kN5Aiof-b3CE91BoBRO2li3CEW6Bs');
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };

    return this._http.post<any[]>('/../postcode/validate?postCode=' + postcode + '', this.httpHeaderOptions);
  }
  submit_akpk_one(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>('/../ssb/application/create', body, this.httpHeaderOptions);
  }
  submit_akpk_two(body: any, apkid: any): Observable<any> {
    return this._http.post<any[]>(
      '/../ssb/application/document/upload?applicationId=' + apkid + '',
      body,
      this.applicationheader
    );
  }
  postcode(postcode: any): Observable<any> {
    return this._http.post<any[]>('/../postcode/validate?postCode=' + postcode + '', this.eligibilityCreteriaOption);
  }
}
