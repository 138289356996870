import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationPartThreeRoutingModule } from './application-part-three-routing.module';
import { ApplicationPartThreeComponent } from './application-part-three.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ApplicationPartThreeComponent],
  imports: [CommonModule, TranslateModule, ApplicationPartThreeRoutingModule, ReactiveFormsModule],
})
export class ApplicationPartThreeModule {}
