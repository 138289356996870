import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserService } from '@app/auth/user.service';
import { CredentialsService } from '@app/auth';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { empty, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  subscription!: Subscription;
  akpktext: any;
  eligible = true;
  button1: string = '';
  button2: string = '';
  resetmessage: string = '';
  resetbutton: string = '';
  resetheader: string = '';
  SESSIONERROR: string = '';
  url: string = '';
  constructor(
    public translate: TranslateService,
    private userservice: UserService,
    private router: Router,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.subscription = interval(60000).subscribe((x) => {
      this.validatetoken();
    });
    if (localStorage.getItem('language') != null && localStorage.getItem('language') != '') {
      const lang = localStorage.getItem('language');
      console.log('lang' + lang);
      this.translate.setDefaultLang(lang ? lang : '');
      this.translate.use(lang ? lang : '');
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    //this.validatetoken();
    this.usersubmittedapplication();

    this.translate.get('AKPK').subscribe((data: any) => {
      this.akpktext = data.HTML;
      this.button1 = data.BUTTON1;
      this.button2 = data.BUTTON2;
      this.resetmessage = data.RESETMESSAGE;
      this.resetbutton = data.RESETBUTTON;
      this.resetheader = data.RESETHEADER;
    });
    this.translate.get('COMMON').subscribe((data: any) => {
      this.SESSIONERROR = data.SESSIONERROR;
    });
    this.url = this.router.url;
  }

  validatetoken() {
    setTimeout(() => {
      const token = this.credentialsService.credentials?.token;
      if (token != null) {
        this.userservice.validate().subscribe(
          (res) => {
            console.log(res);
          },
          (error) => {
            if (error.statusText != 'OK') {
              Swal.fire({
                title: 'Oops...',
                text: this.SESSIONERROR,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
              }).then((result: any) => {
                if (result.isConfirmed) {
                  this.credentialsService.setCredentials();
                  localStorage.removeItem('bearer');
                  localStorage.removeItem('credentials');
                  window.open('/main-page', '_self');
                  // this.router.navigate(['/main-page']);
                }
              });
            }
          }
        );
      }
    }, 5000);
  }

  switch(language: any) {
    /** For Malashiyan */
    localStorage.setItem('language', language);
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.translate.get('AKPK').subscribe((data: any) => {
      this.akpktext = data.HTML;
      this.button1 = data.BUTTON1;
      this.button2 = data.BUTTON2;
      this.resetmessage = data.RESETMESSAGE;
      this.resetbutton = data.RESETBUTTON;
      this.resetheader = data.RESETHEADER;
    });
    location.reload();
  }

  usersubmittedapplication() {
    this.userservice.getapplication().subscribe((responce) => {
      var data = responce.find(function (ele: any) {
        return ele.orderStatus === 'SUBMITTED';
      });
      if (data) {
        this.eligible = true;
      } else {
        this.eligible = false;
      }
    });
  }

  getAKPK() {
    //window.open(environment.apiUrl + 'api/download-attachment/akpk.docx', '');
    // if (localStorage.getItem('language') == 'en') {
    //   this.userservice.getakpkdocument();
    // } else {
    //   this.userservice.getakpkdocumentmy();
    // }

    Swal.fire({
      html: this.akpktext,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: this.button1,
      cancelButtonText: this.button2,
      cancelButtonColor: '#0f5298',
      confirmButtonColor: '#0f5298',
      denyButtonColor: '#0f5298',
      // allowOutsideClick: false,
      showCloseButton: false,
      denyButtonText: this.button2,

      // icon: 'success',
    }).then((result) => {
      if (result.isConfirmed) {
        // window.open('https://mybijakkewangan.akpk.org.my/', '_blank');
        if (localStorage.getItem('language') == 'en') {
          this.userservice.getakpkdocument();
        } else {
          this.userservice.getakpkdocumentmy();
        }
      } else if (result.isDismissed) {
        // Swal.fire('Changes are not saved', '', 'info');
        this.router.navigate(['/dashboard']);
      } else if (result.isDenied) {
        window.open('https://mybijakkewangan.akpk.org.my/', '_blank');
      }
    });
  }
}
